/* eslint-disable camelcase */
/* eslint-disable max-lines */
import React from 'react';

const ModelsMetricsHeader: React.FC = () => {
  const handleAddNew = (): void => {
    // moot
  };

  return (
    <div className="flex flex-row justify-between">
      <span className="text-heading-1">Performance Metrics</span>
      <button type="button" className="button button--primary h-8" onClick={handleAddNew}>
        Add New
      </button>
    </div>
  );
};

export default ModelsMetricsHeader;
