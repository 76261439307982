import { createSelector } from '@reduxjs/toolkit';
import {
  fetchAllIdentifiersRequest,
  fetchIdentifierCustomersRequest,
  fetchIdentifierTypesRequest,
  fetchRelationshipTypesRequest,
  fetchSingleIdentifierRequest,
  generateTermsRequest,
  saveIdentifierRequest,
} from 'actions/identifier';
import { mapInputChoices, possibleTypes } from 'constants/annotator';
import { isEqual } from 'lodash';
import { GlobalState } from 'reducers';
import type {
  AnnotatorTypes,
  Customer,
  Identifier,
  LanguageMatcher,
  LanguageMatcherForm,
  LanguageMatcherType,
  LanguageMatchersTypes,
  MLanguageMatcher,
  NormalizedIdentifier,
  NormalizedRelationshipType,
  NormalizedResource,
  Selector,
  UUID,
} from 'types';
import { UtilizationType } from './config';

export const getFetchAllIdentifiersLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchAllIdentifiersRequest.toString());

export const getFetchIdentifiersTypesLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchIdentifierTypesRequest.toString());

export const saveIdentifierRequestLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(saveIdentifierRequest.toString());

export const getIdentifiers: Selector<NormalizedIdentifier[]> = createSelector(
  [(state: GlobalState): NormalizedResource<NormalizedIdentifier> => state.identifier.items],
  (identifiers) => Object.values(identifiers)
);

export const getIdentifiersForFilterPills: Selector<NormalizedIdentifier[]> = createSelector(
  [(state: GlobalState): NormalizedResource<NormalizedIdentifier> => state.identifier.items],
  (filterItems) => Object.keys(filterItems).map((uuid) => filterItems[uuid])
);

export const getIdentifierTypes: Selector<AnnotatorTypes> = (state) => state.identifier.types;
export const getIdentifierTypesRequestLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchIdentifierTypesRequest.toString());

export const getIdentifier: Selector<NormalizedIdentifier, [UUID]> = (state, id) =>
  state.identifier.items[id];

export const getSelectedIdentifier: Selector<Identifier | null> = (state) =>
  state.identifier.selectedIdentifier;

export const getSelectedIdentifierRevision: Selector<NormalizedIdentifier | null> = (state) =>
  state.identifier.selectedIdentifierRevision;

export const getIdentifierCustomers: Selector<Customer[]> = (state) =>
  state.identifier.identifierCustomers;

export const getIdentifierRequestLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchSingleIdentifierRequest.toString());

export const unsavedChanges: Selector<boolean> = (state) => state.identifier.unsavedChanges;

export const getSelectedCustomerIdentifier: Selector<string> = (state) =>
  state.identifier.selectedCustomer;

export const filterLanguageMatchers = (
  languageMatchers: MLanguageMatcher[],
  languageMatcherFilter: string
): MLanguageMatcher[] => {
  if (languageMatcherFilter === '') {
    return languageMatchers;
  }
  return languageMatchers.filter((el) => {
    const responseArr = Object.values(el).map((value) => {
      if (Array.isArray(value)) {
        const response = value.map(
          (item) => item.toUpperCase().indexOf(languageMatcherFilter) > -1
        );
        if (response.some((val) => val)) return true;
        return false;
      }
      if (typeof value === 'string') {
        return value.toUpperCase().indexOf(languageMatcherFilter) > -1;
      }
      return false;
    });

    if (responseArr.some((val) => val)) return true;
    return false;
  });
};

export const getLanguageMatcherByIdentifierId: Selector<MLanguageMatcher[], [UUID, string]> =
  createSelector(
    [
      getIdentifier,
      (state: GlobalState): NormalizedResource<NormalizedIdentifier> => state.identifier.items,
      (state: GlobalState): NormalizedResource<MLanguageMatcher> =>
        state.identifier.languageMatchers,
      (_state: GlobalState, identifierId): string => identifierId,
      (_state: GlobalState, _identifierId, languageMatcherFilter): string => languageMatcherFilter,
    ],
    (identifier, identifiers, identifierMatchers, identifierId, languageMatcherFilter) => {
      const languageMatchers: LanguageMatcher[] = [];
      if (!identifierId || !Object.keys(identifiers).length) {
        return languageMatchers;
      }

      if (!identifier) {
        return languageMatchers;
      }

      identifier.language_matchers.forEach((key) => {
        const languageMatcher = identifierMatchers[key];
        if (languageMatcher) {
          languageMatchers.push(languageMatcher);
        }
      });

      return filterLanguageMatchers(languageMatchers, languageMatcherFilter);
    }
  );

export const getLanguageMatcherFromSelectedIdentifier: Selector<MLanguageMatcher[], [string]> =
  createSelector(
    [
      getSelectedIdentifierRevision,
      getIdentifierCustomers,
      (state: GlobalState): NormalizedResource<MLanguageMatcher> =>
        state.identifier.languageMatchers,
      (_state: GlobalState, languageMatcherFilter): string => languageMatcherFilter,
    ],
    (identifier, customers, identifierMatchers, languageMatcherFilter) => {
      const languageMatchers: MLanguageMatcher[] = [];

      if (!identifier) {
        return languageMatchers;
      }

      identifier.language_matchers.forEach((key) => {
        const languageMatcher = identifierMatchers[key];
        if (languageMatcher) {
          languageMatchers.push(languageMatcher);
        }
      });

      return filterLanguageMatchers(languageMatchers, languageMatcherFilter).sort((a, b) => {
        if (a.is_exclusion && !b.is_exclusion) {
          return 1;
        }

        if (b.is_exclusion && !a.is_exclusion) {
          return -1;
        }

        const scopedA =
          a.customer_uuids?.filter((c) => customers.find((cu) => cu.uuid === c)) || [];
        const scopedB =
          b.customer_uuids?.filter((c) => customers.find((cu) => cu.uuid === c)) || [];

        if (scopedA.length > scopedB.length) {
          return -1;
        }

        return 1;
      });
    }
  );

export const getSingleLanguageMatcher: Selector<MLanguageMatcher | null, [UUID]> = (
  state,
  languageMatcherId
) => {
  if (!languageMatcherId || !Object.keys(state.identifier.languageMatchers).length) {
    return null;
  }

  return state.identifier.languageMatchers[languageMatcherId];
};

export const getLanguageMatchers: Selector<MLanguageMatcher[]> = createSelector(
  [
    (state: GlobalState): GlobalState['identifier']['languageMatchers'] =>
      state.identifier.languageMatchers,
  ],
  (languageMatchers) => Object.values(languageMatchers)
);

export const getActiveLanguageMatcher: Selector<MLanguageMatcher> = (state) =>
  state.identifier.languageMatchers[state.identifier.activeLanguageMatcher];

export const getOriginalActiveLanguageMatcher: Selector<LanguageMatcher | undefined> = (state) => {
  const matcher = state.identifier.originalIdentifier?.identifierRevision.language_matchers.find(
    (l) => l.uuid === state.identifier.activeLanguageMatcher
  );
  return matcher;
};

export const getActiveLanguageMatcherId: Selector<string> = (state) =>
  state.identifier.activeLanguageMatcher;

export const getLanguageMatcherTypes: Selector<LanguageMatchersTypes> = (state) =>
  state.identifier.languageMatcherTypes;

export const getRelationshipTypes: Selector<NormalizedRelationshipType[]> = (state) =>
  state.identifier.relationshipTypes;

export const getFetchRelationshipTypesLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchRelationshipTypesRequest.toString());

export const getIdentifiersTotalCount: Selector<number> = (state) => state.identifier.count;

export const getGeneratedTerms: Selector<string[]> = (state) =>
  state.identifier.generatedTerms[state.identifier.activeLanguageMatcher] || [];

export const getGeneratedTermsLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(generateTermsRequest.toString());

export const getDropPosition: Selector<number> = (state) => state.identifier.dropPosition;

export const getCurrentTestIdentifierRevisionId: Selector<string> = (state) =>
  state.identifier.testIdentifierRevisionId;

export const getIdentifierShowUtilization: Selector<boolean> = (state) =>
  state.identifier.showUtilization;

export const getShowEditIdentifier: Selector<boolean> = (state) =>
  state.identifier.showEditIdentifier;

// FIXME: keyof LanguageMatcher should be dependent of the LanguageMatcherType
export const getFormSpec: Selector<
  LanguageMatcherForm,
  [LanguageMatcherType, keyof LanguageMatcher, UUID]
> = createSelector(
  [
    (state: GlobalState): LanguageMatchersTypes => state.identifier.languageMatcherTypes,
    (state: GlobalState, _type, _form, languageMatcherId): MLanguageMatcher | null =>
      getSingleLanguageMatcher(state, languageMatcherId),
    getOriginalActiveLanguageMatcher,
    (_state: GlobalState, type): LanguageMatcherType => type,
    (_state: GlobalState, _type, form): keyof LanguageMatcher => form,
  ],
  (matcherTypes, languageMatcher, originalLanguageMacher, type, form) => {
    const inputItem = matcherTypes[type][form];
    if (inputItem == null) {
      throw new Error(`Invalid form: ${form} for type: ${type}`);
    }

    const inputValue = languageMatcher ? languageMatcher[form] : null;
    const originalValue = originalLanguageMacher ? originalLanguageMacher[form] : null;
    const inputErrors =
      languageMatcher && 'errorFields' in languageMatcher ? languageMatcher.errorFields : null;
    const inputChoices =
      inputItem.choices != null ? mapInputChoices(inputItem.choices, form) : null;

    let inputType = possibleTypes.freeForm;
    if (inputChoices !== null) {
      if (inputItem.is_list === true) {
        inputType = possibleTypes.multiSelect;
      } else {
        inputType = possibleTypes.singleSelect;
      }
    } else if (inputItem.is_list === true) {
      inputType = possibleTypes.freeForm;
    } else if (inputItem.type === 'bool') {
      inputType = possibleTypes.bool;
    } else if (inputItem.type === 'int') {
      inputType = possibleTypes.int;
    }

    return {
      inputType,
      inputValue,
      originalValue,
      inputErrors,
      inputChoices,
      inputName: inputItem.name,
      inputRequired: inputItem.required,
      inputHidden: inputItem.hidden,
    };
  }
);

export const getSelectedMatcherScopesForUser: Selector<string[]> = createSelector(
  [getActiveLanguageMatcher, getIdentifierCustomers],
  (matcher, customers) => {
    if (matcher) {
      const scopedCustomers = matcher.customer_uuids || [];
      const scopedCustomersForUser = scopedCustomers.filter((c) =>
        customers.find((cu) => cu.uuid === c)
      );
      return scopedCustomersForUser;
    }
    return [];
  }
);

export const getOriginalMatcherUtilization: Selector<UtilizationType, [UUID]> = (state, itemId) => {
  const matcher = state.identifier.originalIdentifier?.identifierRevision.language_matchers.find(
    (l) => l.uuid === itemId
  );
  const scopedCustomers = matcher?.customer_uuids || [];
  const customers = getIdentifierCustomers(state);

  const scopedCustomersForUser = scopedCustomers.filter((c) =>
    customers.find((cu) => cu.uuid === c)
  );

  if (scopedCustomersForUser.length === customers.length) return 'all';
  if (
    scopedCustomersForUser.length >= customers.length / 2 &&
    scopedCustomersForUser.length < customers.length
  )
    return 'more-than-half';
  if (scopedCustomersForUser.length > 1 && scopedCustomersForUser.length < customers.length / 2)
    return 'less-than-half';
  if (scopedCustomersForUser.length === 1) return 'one';

  return 'none';
};

export const getMatcherUtilization: Selector<UtilizationType, [UUID]> = (state, itemId) => {
  const matcher = getSingleLanguageMatcher(state, itemId);
  const scopedCustomers = matcher?.customer_uuids || [];
  const customers = getIdentifierCustomers(state);

  const scopedCustomersForUser = scopedCustomers.filter((c) =>
    customers.find((cu) => cu.uuid === c)
  );

  if (scopedCustomersForUser.length === customers.length) return 'all';
  if (
    scopedCustomersForUser.length >= customers.length / 2 &&
    scopedCustomersForUser.length < customers.length
  )
    return 'more-than-half';
  if (scopedCustomersForUser.length > 1 && scopedCustomersForUser.length < customers.length / 2)
    return 'less-than-half';
  if (scopedCustomersForUser.length === 1) return 'one';

  return 'none';
};

export const getOriginalScopedCustomersMatcher: Selector<string[], [UUID]> = (state, id) => {
  const item = state.identifier.originalIdentifier?.identifierRevision.language_matchers.find(
    (l) => l.uuid === id
  );
  if (item && 'customer_uuids' in item) {
    return item.customer_uuids || [];
  }

  return [];
};

export const getScopedCustomersMatcher: Selector<string[], [UUID]> = (state, id) => {
  const item = state.identifier.languageMatchers[id];
  if ('customer_uuids' in item) {
    return item.customer_uuids || [];
  }

  return [];
};

export const getFetchIdentifierCustomersLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchIdentifierCustomersRequest.toString());

export const getLanguageMatcherTermsSearchQuery: Selector<string> = (state) => {
  const languageMatchers = getLanguageMatcherFromSelectedIdentifier(state, '');

  const terms = languageMatchers
    .filter((l) => l.type === 'token')
    .reduce<string[]>((acc, curr) => {
      if (!curr.terms || curr.terms.length === 0) return acc;

      const arr = curr.terms.reduce<string[]>((ac, cu) => [...ac, cu], []);

      return [...acc, ...arr];
    }, []);

  const query = [...new Set(terms)].reduce((acc, curr, idx) => {
    if (idx === 0) {
      return `"${curr}"`;
    }
    return `${acc} | "${curr}"`;
  }, '');

  return query;
};

export const getChangedLanguageMatchers: Selector<string[]> = createSelector(
  [
    getLanguageMatchers,
    (state: GlobalState): LanguageMatcher[] =>
      state.identifier.originalIdentifier?.identifierRevision.language_matchers || [],
  ],
  (languageMatchers, originalLanguageMatchers) => {
    const changed: string[] = [];

    languageMatchers.forEach((matcher) => {
      const originalMatcher = originalLanguageMatchers.find(
        (original) => original.uuid === matcher.uuid
      );

      if (!originalMatcher) {
        changed.push(matcher.uuid);
        return;
      }

      Object.keys(matcher).forEach((key) => {
        if (
          key !== 'saved' &&
          !isEqual(
            matcher[key as keyof typeof matcher],
            originalMatcher[key as keyof typeof originalMatcher]
          )
        ) {
          changed.push(matcher.uuid);
        }
      });

      /* if (!isEqual(matcher, originalMatcher)) {
        changed.push(matcher.uuid);
      } */
    });

    return [...new Set(changed)];
  }
);
