/* eslint-disable max-lines */
import {
  changeLanguageMatcherType,
  editLanguageMatcher,
  fetchLanguageMatcherTypes,
  receiveNewKeyword,
} from 'actions/identifier';
import ToggleButton from 'components/ToggleButton';
import WarningMsg from 'components/WarningMsg';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getActiveLanguageMatcher,
  getActiveLanguageMatcherId,
  getLanguageMatcherTypes,
  getOriginalActiveLanguageMatcher,
  saveIdentifierRequestLoading,
  unsavedChanges,
} from 'selectors/identifier';
import { useSelector } from 'store';
import { languageMatcherNotificationMsgs } from 'utils/userNotificationMsgs';
import AddLanguageMatcherInput from './AddLanguageMatcherInput';
import TermsInput from './AddLanguageMatcherInput/TermsInput';
import ChooseCombinationType from './ChooseCombinationType';
import DeleteMatcherButton from './DeleteMatcherButton';

type LanguageMatcherType = {
  isSaved: boolean;
};

const TokenTypeForm: React.FC<LanguageMatcherType> = ({ isSaved }) => {
  const dispatch = useDispatch();

  const unsaved = useSelector(unsavedChanges);
  const combinations = useSelector(getLanguageMatcherTypes);
  const activeLanguageMatcher = useSelector(getActiveLanguageMatcher);
  const originalActiveLanguageMatcher = useSelector(getOriginalActiveLanguageMatcher);
  const activeLanguageMatcherId = useSelector(getActiveLanguageMatcherId);
  const isLoadingSaveIdentifier = useSelector(saveIdentifierRequestLoading);
  const languageMatcherTypes = useSelector(getLanguageMatcherTypes);

  const tokenExcludedFields = [
    'ent_types',
    'is_exclusion',
    'team_uuids',
    'tenses',
    'terms',
    'not_team_uuids',
    'pos',
    'type',
    'status',
  ];

  const [combinationSelection, setCombinationSelection] = useState<string>(
    activeLanguageMatcher.type
  );

  useEffect(() => {
    if (activeLanguageMatcher && activeLanguageMatcher.isNew) {
      const input = document.getElementById('name');
      input?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguageMatcherId]);

  useEffect(() => {
    dispatch(fetchLanguageMatcherTypes());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setCombinationSelection(e.target.value);
    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(combinations[e.target.value as keyof typeof combinations]).forEach(
      ([key, field]) => {
        if (field != null && field.default) {
          defaultValues[key] = field.default;
        }
      }
    );
    dispatch(
      changeLanguageMatcherType({
        languageMatcherId: activeLanguageMatcher.uuid,
        type: e.target.value,
        ...defaultValues,
      })
    );
  };

  const onEditLanguageMatcher = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!activeLanguageMatcher.uuid || e.target.value === null) return;
    dispatch(
      editLanguageMatcher({
        languageMatcherId: activeLanguageMatcher.uuid,
        data: { [e.target.name]: e.target.value },
      })
    );
  };

  const handleToggleClick = (field: string): void => {
    // @ts-ignore
    const value = !activeLanguageMatcher[field];

    dispatch(
      receiveNewKeyword({
        languageMatcherId: activeLanguageMatcherId,
        parentType: 'token',
        data: {
          // @ts-ignore
          key: field,
          value,
        },
      })
    );

    dispatch(
      receiveNewKeyword({
        languageMatcherId: activeLanguageMatcherId,
        parentType: 'token',
        data: {
          key: 'saved',
          value: false,
        },
      })
    );
  };

  const isLanguageMatcherNew =
    !activeLanguageMatcher || (activeLanguageMatcher && activeLanguageMatcher.isNew);

  if (Object.keys(languageMatcherTypes).length === 0) return null;

  return (
    <div className="flex flex-col gap-4 pt-4 px-8 pb-10 text-body">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-3">
          <ToggleButton
            // @ts-ignore
            isOn={activeLanguageMatcher.is_exclusion as boolean}
            handleToggleClick={(): void => handleToggleClick('is_exclusion')}
          />
          <span>Exclude matches</span>
        </div>

        <WarningMsg
          message={languageMatcherNotificationMsgs.unsavedChanges}
          showLoading={isLoadingSaveIdentifier}
          showWarning={unsaved}
          showSavedMsg={isSaved}
        />
      </div>
      <div className="flex flex-row gap-5">
        <label htmlFor="name" className="flex flex-col gap-1 text-body w-1/2">
          <div className="flex flex-row">
            <span>Name</span>
            <span className="text-litlingo-alert">*</span>
          </div>
          <input
            name="name"
            id="name"
            data-testid="matcher-name-input"
            value={activeLanguageMatcher.name}
            className={`form-input w-full py-2 px-4 border border-litlingo-gray-2 rounded-lg text-body focus:outline-none ${
              activeLanguageMatcher.name !== originalActiveLanguageMatcher?.name
                ? 'bg-litlingo-focus-area-background'
                : ''
            }`}
            onChange={onEditLanguageMatcher}
          />
        </label>

        <label htmlFor="keyword" className="flex flex-col gap-1 text-body w-60">
          <div>Type</div>
          {!isLanguageMatcherNew ? (
            <div className="py-2 px-4 w-full bg-litlingo-gray-0.5 border border-litlingo-gray-2 rounded-lg">
              Token
            </div>
          ) : (
            <div>
              <select
                value={combinationSelection}
                onChange={handleChangeType}
                id="Mode Of Speech"
                data-testid="select-language-matcher-type"
                className="block form-select w-full border border-litlingo-gray-2 disabled:bg-litlingo-gray-2 h-9.5"
                disabled={!isLanguageMatcherNew}
              >
                {Object.keys(combinations).map((combination) => (
                  <option key={combination} value={combination}>
                    {combination}
                  </option>
                ))}
              </select>
              {combinationSelection === 'keyword' ? (
                <span className="text-xs">WARNING: Please Do Not use!!</span>
              ) : (
                ''
              )}
              <p className="mt-2 text-sm text-gray-500">
                {combinations[combinationSelection as keyof typeof combinations].description &&
                  combinations[combinationSelection as keyof typeof combinations].description}
              </p>
            </div>
          )}
        </label>
      </div>

      <div className="flex flex-col gap-1">
        <TermsInput
          languageMatcherId={activeLanguageMatcherId}
          type="token"
          // @ts-ignore
          form="terms"
          enableGenerate
          enableImport
        />
      </div>

      <div className="flex flex-row gap-5">
        <div className="w-1/2">
          <AddLanguageMatcherInput
            languageMatcherId={activeLanguageMatcherId}
            type="token"
            // @ts-ignore
            form="pos"
          />
        </div>
        <div className="w-1/4">
          <AddLanguageMatcherInput
            languageMatcherId={activeLanguageMatcherId}
            type="token"
            // @ts-ignore
            form="tenses"
          />
        </div>
        <div className="w-1/4">
          <AddLanguageMatcherInput
            languageMatcherId={activeLanguageMatcherId}
            type="token"
            // @ts-ignore
            form="ent_types"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-6">
        <TermsInput
          languageMatcherId={activeLanguageMatcherId}
          type="token"
          // @ts-ignore
          form="team_uuids"
        />

        <TermsInput
          languageMatcherId={activeLanguageMatcherId}
          type="token"
          // @ts-ignore
          form="not_team_uuids"
          useDisabledColor
        />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-3">
          <ToggleButton
            // @ts-ignore
            isOn={activeLanguageMatcher.is_negated as boolean}
            handleToggleClick={(): void => handleToggleClick('is_negated')}
          />
          <span>Token Negated</span>
        </div>
        <DeleteMatcherButton
          text="Delete Matcher"
          setValue={(): void => {
            // moot
          }}
          setHighlightedLanguageMatcher={(): void => {
            // moot
          }}
          activeLanguageMatcher={activeLanguageMatcher}
        />
      </div>

      <div>
        <ChooseCombinationType
          activeLanguageMatcher={activeLanguageMatcher}
          excludedFields={tokenExcludedFields}
        />
      </div>
    </div>
  );
};

export default TokenTypeForm;
