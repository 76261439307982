/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { clearURLParams, setURLParams } from 'actions';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import SearchInput from 'components/SearchInput';
import SelectRedesign from 'components/Select/SelectRedesign';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerId } from 'selectors/auth';
import { getExperiments } from 'selectors/envelopes';
import { getNavParamsByResource } from 'selectors/nav';
import { getMetricCustomers } from 'selectors/users';
import { useSelector } from 'store';
import { ModelMetricsType } from './ModelMetricsCardsSection';

type ComponentProps = {
  resource: string;
  setPerformanceFn: React.Dispatch<
    React.SetStateAction<((a: ModelMetricsType, b: ModelMetricsType) => number) | undefined>
  >;
};

const ModelMetricsFilters: React.FC<ComponentProps> = ({ resource, setPerformanceFn }) => {
  const dispatch = useDispatch();
  const metrics = useSelector(getExperiments);
  const customers = useSelector(getMetricCustomers);
  const currenCustomer = useSelector(getCustomerId);

  const [performance, setPerformance] = useState('');
  const customerFilter = (useSelector(getNavParamsByResource(resource)).customer_uuids ||
    []) as string[];
  const typeFilter = (useSelector(getNavParamsByResource(resource)).type || '') as string;

  useEffect(() => {
    dispatch(
      setURLParams({
        [`${resource}__customer_uuids`]: [currenCustomer || ''],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedCustomers = customerFilter.length
    ? customerFilter.map((f) => ({ label: f, value: f }))
    : [
        {
          label: currenCustomer || '',
          value: currenCustomer || '',
        },
      ];

  const performanceOptions = [
    {
      label: 'Precision (High to low)',
      value: 'precisionHighToLow',
    },
    {
      label: 'Precision (Low to High)',
      value: 'precisionLowToHigh',
    },
    {
      label: 'Recall (High to low)',
      value: 'recallHighToLow',
    },
    {
      label: 'Recall (Low to High)',
      value: 'recallLowToHigh',
    },
    {
      label: 'F1 (High to low)',
      value: 'f1HighToLow',
    },
    {
      label: 'F1 (Low to High)',
      value: 'f1LowToHigh',
    },
    {
      label: 'F2 (High to low)',
      value: 'f2HighToLow',
    },
    {
      label: 'F2 (Low to High)',
      value: 'f2LowToHigh',
    },
  ];

  const typeOptions = [
    {
      label: 'Production',
      value: 'production',
    },
    {
      label: 'Development',
      value: 'development',
    },
    {
      label: 'Testing',
      value: 'testing',
    },
    {
      label: 'All',
      value: 'all',
    },
  ];

  const customerOptions = customers.map((cus) => ({
    label: cus.name || '',
    value: cus.uuid || '',
  }));

  const handlePerformanceFilterChange = (option: string): void => {
    setPerformance(option);
    switch (option) {
      case 'precisionHighToLow':
        setPerformanceFn(
          () => (a: ModelMetricsType, b: ModelMetricsType) => b.precision - a.precision
        );
        break;
      case 'precisionLowToHigh':
        setPerformanceFn(
          () => (a: ModelMetricsType, b: ModelMetricsType) => a.precision - b.precision
        );
        break;
      case 'recallHighToLow':
        setPerformanceFn(() => (a: ModelMetricsType, b: ModelMetricsType) => b.recall - a.recall);
        break;
      case 'recallLowToHigh':
        setPerformanceFn(() => (a: ModelMetricsType, b: ModelMetricsType) => a.recall - b.recall);
        break;
      case 'f1HighToLow':
        setPerformanceFn(() => (a: ModelMetricsType, b: ModelMetricsType) => b.f1 - a.f1);
        break;
      case 'f1LowToHigh':
        setPerformanceFn(() => (a: ModelMetricsType, b: ModelMetricsType) => a.f1 - b.f1);
        break;
      case 'f2HighToLow':
        setPerformanceFn(() => (a: ModelMetricsType, b: ModelMetricsType) => b.f2 - a.f2);
        break;
      case 'f2LowToHigh':
        setPerformanceFn(() => (a: ModelMetricsType, b: ModelMetricsType) => a.f2 - b.f2);
        break;
      default:
        break;
    }
  };

  const handleTypeFilterChange = (option: string): void => {
    if (typeFilter === option) {
      dispatch(clearURLParams());
    } else {
      if (option === 'all') {
        dispatch(clearURLParams());
        return;
      }
      dispatch(
        setURLParams({
          [`${resource}__type`]: option,
        })
      );
    }
  };

  const handleCustomerFilterChange = (
    option: MultiSelectOption,
    _exclude: boolean | undefined,
    e: React.MouseEvent<HTMLInputElement, MouseEvent> | undefined
  ): void => {
    e?.stopPropagation();

    const idx = customerFilter.indexOf(option.value);
    if (idx === -1) {
      dispatch(
        setURLParams({
          [`${resource}__customer_uuids`]: [...customerFilter, option.value],
        })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__customer_uuids`]: [
            ...customerFilter.slice(0, idx),
            ...customerFilter.slice(idx + 1),
          ],
        })
      );
    }
  };

  const handleClearAll = (): void => {
    dispatch(clearURLParams());
    setPerformance('');
  };

  const modelsCount = metrics.reduce((acc, val) => acc + val.testing.length, 0);

  return (
    <div className="flex flex-col gap-2">
      <SearchInput
        resource={resourceQueryParamName.envelopes}
        field="name"
        className="h-8 max-w-68"
        placeholder="Search Models"
      />
      <div className="flex flex-row justify-between">
        <div className="flex flex-trow gap-4">
          <div className="flex flex-trow gap-2">
            <div className="w-35">
              <SelectRedesign
                className="bg-white"
                optionClassname="bg-white"
                placeholder="Performance"
                options={performanceOptions}
                onChange={handlePerformanceFilterChange}
                value={performance}
              />
            </div>
            <div className="w-35">
              <SelectRedesign
                className="bg-white"
                optionClassname="bg-white"
                placeholder="Type"
                options={typeOptions}
                onChange={handleTypeFilterChange}
                value={typeFilter || 'all'}
              />
            </div>
            <div className="w-35">
              <MultiselectDropdownRedesign
                placeholder="Customers"
                onChange={handleCustomerFilterChange}
                options={customerOptions}
                selectedItems={selectedCustomers}
                dataTestid="user-customers"
              />
            </div>
          </div>
          <button
            type="button"
            className="text-body self-end underline focus:outline-none"
            onClick={handleClearAll}
          >
            Clear All
          </button>
        </div>
        <span className="self-end text-body">{modelsCount} Models</span>
      </div>
    </div>
  );
};

export default ModelMetricsFilters;
