import { UUID } from '@litlingo/client';
import ScopesSection from 'components/LanguageMatcherRedesign/ScopesSection';
import { bgColorsUtilization, textUtilization } from 'constants/rowItem';
import React from 'react';
import {
  getAnnotationMatchedLanguage,
  getAnnotationMatchedLanguageV2,
  getShowV2Graph,
} from 'selectors/communications';
import {
  getIdentifierShowUtilization,
  getMatcherUtilization,
  getOriginalMatcherUtilization,
  getScopedCustomersMatcher,
  getSelectedCustomerIdentifier,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { MLanguageMatcher } from 'types';

type ComponentProps = {
  languageMatcher: MLanguageMatcher;
  highlightedLanguageMatcher: string | undefined;
  handleLanguageMatcherClick: (languageMatcherId: UUID) => void;
};

const LanguageMatcherSidebarItem: React.FC<ComponentProps> = ({
  languageMatcher,
  highlightedLanguageMatcher,
  handleLanguageMatcherClick,
}) => {
  const selectedCustomer = useSelector(getSelectedCustomerIdentifier);
  const utilization = useSelector((state) => getMatcherUtilization(state, languageMatcher.uuid));
  const originalUtilization = useSelector((state) =>
    getOriginalMatcherUtilization(state, languageMatcher.uuid)
  );
  const scopedCustomers = useSelector((state) =>
    getScopedCustomersMatcher(state, languageMatcher.uuid)
  );
  const showV2 = useSelector(getShowV2Graph);

  const annotationsMatched = useSelector(getAnnotationMatchedLanguage);
  const annotationsMatchedV2 = useSelector(getAnnotationMatchedLanguageV2);

  const showUtilization = useSelector(getIdentifierShowUtilization);

  return (
    <div
      className={`relative flex flex-col border-b border-litlingo-gray-1 overflow-visible ${
        languageMatcher.uuid === highlightedLanguageMatcher ? 'z-10' : 'z-0'
      }`}
    >
      <div
        className={`relative pl-4.5 pr-4 flex flex-row bg-white z-20 ${
          showUtilization && bgColorsUtilization[utilization]
        }`}
      >
        {selectedCustomer && scopedCustomers.includes(selectedCustomer) && (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-litlingo-green-bg bg-opacity-50" />
        )}

        <div
          key={languageMatcher.uuid}
          aria-hidden="true"
          className="relative py-2 flex flex-row justify-between cursor-pointer w-full overflow-hidden z-10"
          onClick={(): void => handleLanguageMatcherClick(languageMatcher.uuid)}
        >
          <div className="flex flex-col gap-1 text-body">
            <span
              className={`truncate ${
                languageMatcher.uuid === highlightedLanguageMatcher ? 'font-bold' : ''
              } ${
                (showV2 && annotationsMatchedV2.includes(languageMatcher.name)) ||
                (!showV2 && annotationsMatched.includes(languageMatcher.name))
                  ? 'bg-litlingo-highlight'
                  : ''
              }`}
            >
              {languageMatcher.is_exclusion ? '-' : ''}
              {languageMatcher.name}
            </span>
            <span className="text-small">{languageMatcher.type}</span>
          </div>
          <div className="h-full flex flex-col justify-between text-body">
            <span className="font-bold text-right">{textUtilization[utilization]}</span>
            {utilization !== originalUtilization && (
              <span className="text-small text-right">{textUtilization[originalUtilization]}</span>
            )}
          </div>
        </div>
        {languageMatcher.uuid === highlightedLanguageMatcher && (
          <div className="absolute left-0 top-0 bottom-0 z-20">
            <svg
              width="10"
              height="100%"
              viewBox="0 0 10 64"
              fill="none"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="10" height="64" fill="#007656" />
            </svg>
          </div>
        )}
      </div>

      {languageMatcher.uuid === highlightedLanguageMatcher && (
        <ScopesSection languageMatcher={languageMatcher} />
      )}
    </div>
  );
};

export default LanguageMatcherSidebarItem;
