import { removeKeyword } from 'actions/identifier';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import type { LanguageMatcher } from 'types';

type ComponentProps = {
  keyword: string;
  id: string;
  type: keyof LanguageMatcher;
  hidden?: boolean;
  useDisabledColor?: boolean;
  originalTerms?: string[];
  removed?: boolean;
};

const LanguageMatcherList: React.FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const {
    keyword,
    id,
    type,
    hidden,
    useDisabledColor = false,
    originalTerms = [],
    removed = false,
  } = props;
  const [ref, setRef] = useState<HTMLParagraphElement | null>(null);

  const onRefChange = useCallback((node) => {
    setRef(node); // e.g. change ref state to trigger re-render
  }, []);

  const getBgColor = (): string => {
    if (removed) return 'bg-litlingo-alert-light';
    if (originalTerms?.length > 0) {
      if (!originalTerms.includes(keyword)) {
        return 'bg-litlingo-success-light';
      }
      return 'bg-litlingo-gray-1';
    }
    if (useDisabledColor) return 'bg-litlingo-alert-light';
    return 'bg-litlingo-success-light';
  };

  return (
    <div className={`${hidden && 'hidden'} flex flex-row items-center`}>
      <div
        className={`px-1 py-0.5 flex flex-row items-center gap-1 text-small rounded ${getBgColor()}`}
      >
        {ref && ref.scrollWidth > ref.clientWidth && <Tooltip id={keyword} />}
        <p className="truncate" data-tip={keyword} data-for={keyword} ref={onRefChange}>
          {keyword}
        </p>
        <button
          className="self-center focus:outline-none"
          type="button"
          onClick={(): void => {
            dispatch(
              removeKeyword({
                keyword,
                languageMatcherId: id,
                partOfSpeech: type,
              })
            );
          }}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill="#ADADAD" />
            <path
              d="M3.56055 3.56006L6.47255 6.47246"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.47255 3.56006L3.56055 6.47246"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default LanguageMatcherList;
