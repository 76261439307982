(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "axios"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("lodash"), require("axios")) : factory(root["lodash"], root["axios"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__6773__, __WEBPACK_EXTERNAL_MODULE__2742__) => {
return 