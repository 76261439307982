import { createAction } from '@reduxjs/toolkit';
import type { API, EntityMetadata, ErrorObject, User, UUID } from 'types';

type FetchUsersForFilterPayload = {
  searchValue: string;
  limit?: number;
  roles?: string[];
  offset?: number;
};
export const fetchUsersForFilter = createAction<FetchUsersForFilterPayload>('USERS_FILTER/FETCH');
export const fetchUsersForFilterAllUsers = createAction<FetchUsersForFilterPayload>(
  'USERS_FILTER/FETCH_ALL_USERS'
);
export const fetchUsers = createAction('USERS/FETCH');
export const fetchReviewers = createAction<FetchUsersForFilterPayload>('USERS/FETCH_REVIEWERS');
export const fetchUsersRequest = createAction('USERS/FETCH_REQUEST');
export const fetchUsersSuccess = createAction<API.WrappedAPIResponse<User>>('USERS/FETCH_SUCCESS');
export const fetchUsersSuccessAppend = createAction<API.WrappedAPIResponse<User>>(
  'USERS/FETCH_SUCCESS_APPEND'
);
export const fetchUsersFailure = createAction<ErrorObject>('USERS/FETCH_FAILURE');

type FetchUsersForFilterPillsPayload = { uuids: string[] };

export const fetchUsersForFilterPills =
  createAction<FetchUsersForFilterPillsPayload>('USERS/FETCH_BY_ID');
export const fetchUsersForFilterPillsRequest = createAction('USERS/FETCH_BY_ID_REQUEST');
export const fetchUsersForFilterPillsSuccess = createAction<API.WrappedAPIResponse<User>>(
  'USERS/FETCH_BY_ID_SUCCESS'
);
export const fetchUsersForFilterPillsFailure = createAction<ErrorObject>(
  'USERS/FETCH_BY_ID_FAILURE'
);

export const selectUser = createAction<User | null>('USERS?SELECT_USER');

export const saveUser = createAction<User>('USERS/SAVE');
export const saveUserRequest = createAction('USERS/SAVE_REQUEST');
export const saveUserSuccess = createAction<User>('USERS/SAVE_SUCCESS');
export const saveUserFailure = createAction<ErrorObject>('USERS/SAVE_FAILURE');

export const fetchUser = createAction<{ userId: UUID }>('USERS/FETCH_USER');
export const fetchUserRequest = createAction('USERS/FETCH_USER_REQUEST');
export const fetchUserSuccess = createAction<User>('USERS/FETCH_USER_SUCCESS');
export const fetchUserFailure = createAction<ErrorObject>('USERS/FETCH_USER_FAILURE');

export const fetchUserRoles = createAction('USERS/FETCH_ROLES');
export const fetchUserRolesRequest = createAction('USERS/FETCH_ROLES_REQUEST');
export const fetchUserRolesSuccess = createAction<string[]>('USERS/FETCH_ROLES_SUCCESS');
export const fetchUserRolesFailure = createAction<ErrorObject>('USERS/FETCH_ROLES_FAILURE');

export const deleteUser = createAction<{ uuids: UUID[] }>('USERS/DELETE');
export const deleteUserRequest = createAction('USERS/DELETE_REQUEST');
export const deleteUserSuccess = createAction<UUID[]>('USERS/DELETE_SUCCESS');
export const deleteUserFailure = createAction<ErrorObject>('USERS/DELETE_FAILURE');

export const undeleteUser = createAction<{ uuid: UUID }>('USERS/UNDELETE');
export const undeleteUserRequest = createAction('USERS/UNDELETE_REQUEST');
export const undeleteUserSuccess = createAction<UUID>('USERS/UNDELETE_SUCCESS');
export const undeleteUserFailure = createAction<ErrorObject>('USERS/UNDELETE_FAILURE');
export const undeleteUserFulfill = createAction('USERS/UNDELETE_FULFILL');

export const unlockEnvelope = createAction('USERS/UNLOCK_ENVELOPE');
export const unlockEnvelopeRequest = createAction('USERS/UNLOCK_ENVELOPE_REQUEST');
export const unlockEnvelopeSuccess = createAction('USERS/UNLOCK_ENVELOPE_SUCCESS');
export const unlockEnvelopeFailure = createAction<ErrorObject>('USERS/UNLOCK_ENVELOPE_FAILURE');
export const unlockEnvelopeFulfill = createAction('USERS/UNLOCK_ENVELOPE_FULFILL');

type SaveUsersPayload = {
  users: User[];
  sendInvitation?: boolean;
  redirect?: boolean;
  fromPermissionsPage?: boolean;
};

export const saveUsers = createAction<SaveUsersPayload>('USERS/SAVE_USERS');
export const saveUsersSuccess = createAction<User[]>('USERS/SAVE_USERS_SUCCESS');
export const saveUsersFailure = createAction<ErrorObject>('USERS/SAVE_USERS_FAILURE');
export const saveUsersFulfill = createAction('USERS/SAVE_USERS_FULFILL');

export const applyPermissionsToSelectedUsersAndSave = createAction(
  'USERS_PERMISSIONS_PAGE/APPLY_PERMISSIONS_SELECTED_USERS_SAVE'
);
export const applyPermissionsToSelectedUsersAndSaveFullFil = createAction<User[]>(
  'USERS_PERMISSIONS_PAGE/APPLY_PERMISSIONS_SELECTED_USERS_SAVE_FULLFIL'
);

export const applyPermissionsToSelectedUsersAndSaveQuery = createAction(
  'USERS_PERMISSIONS_PAGE/APPLY_PERMISSIONS_SELECTED_USERS_SAVE_QUERY'
);
export const applyPermissionsToSelectedUsersAndSaveQueryFullFil = createAction(
  'USERS_PERMISSIONS_PAGE/APPLY_PERMISSIONS_SELECTED_USERS_SAVE_QUERY_FULLFIL'
);

export const applyPermissionsToSingleSelectedUserAndSave = createAction(
  'USERS_PERMISSIONS_PAGE/APPLY_PERMISSIONS_SINGLE_SELECTED_USER_SAVE'
);
export const applyPermissionsToSingleSelectedUserAndSaveFullfil = createAction<User[]>(
  'USERS_PERMISSIONS_PAGE/APPLY_PERMISSIONS_SINGLE_SELECTED_USER_SAVE_FULLFIL'
);

export type addUserMetadataPayload = {
  uuid?: UUID;
  userUuid: UUID;
  type?: string;
  data: {
    showHighlights: boolean;
  };
};

export const addUserMetadata = createAction<addUserMetadataPayload>('USERS/ADD_METADATA');
export const addUserMetadataRequest = createAction('USERS/ADD_METADATA_REQUEST');
export const addUserMetadataSuccess = createAction<EntityMetadata>('USERS/ADD_METADATA_SUCCESS');
export const addUserMetadataFailure = createAction<ErrorObject>('USERS/ADD_METADATA_FAILURE');
export const addUserMetadataFulfill = createAction('USERS/ADD_METADATA_FULFILL');

export const upsertUserMetadata = createAction<EntityMetadata>('USERS/UPSERT_METADATA');
export const upsertUserMetadataRequest = createAction('USERS/UPSERT_METADATA_REQUEST');
export const upsertUserMetadataSuccess = createAction<EntityMetadata>(
  'USERS/UPSERT_METADATA_SUCCESS'
);
export const upsertUserMetadataFailure = createAction<ErrorObject>('USERS/UPSERT_METADATA_FAILURE');
export const upsertUserMetadataFulfill = createAction('USERS/UPSERT_METADATA_FULFILL');

export const fetchUserMetadata = createAction<{ userId: UUID }>('USERS/FETCH_USER_METADATA');
export const fetchUserMetadataRequest = createAction('USERS/FETCH_USER_REQUEST_METADATA');
export const fetchUserMetadataSuccess = createAction<EntityMetadata>(
  'USERS/FETCH_USER_METADATA_SUCCESS'
);

export type DomainResponsePayload = API.Users.SenderDomains & { count: number };
export const fetchUserMetadataFailure = createAction<ErrorObject>(
  'USERS/FETCH_USER_METADATA_FAILURE'
);
export const fetchUserMetadataFulfill = createAction('USERS/FETCH_USER_METADATA_FULFILL');

export const fetchCustomerUsersDomain = createAction<FetchUsersForFilterPayload>(
  'USERS/FETCH_CUSTOMER_USERS_DOMAIN'
);
export const fetchCustomerUsersDomainRequest = createAction(
  'USERS/FETCH_CUSTOMER_USERS_DOMAIN_REQUEST'
);
export const fetchCustomerUsersDomainSuccess = createAction<DomainResponsePayload>(
  'USERS/FETCH_CUSTOMER_USERS_DOMAIN_SUCCESS'
);
export const fetchCustomerUsersDomainFailure = createAction<ErrorObject>(
  'USERS/FETCH_CUSTOMER_USERS_DOMAIN_FAILURE'
);

export const fetchCustomerRecipientsDomain = createAction<FetchUsersForFilterPayload>(
  'USERS/FETCH_CUSTOMER_RECIPIENTS_DOMAIN'
);
export const fetchCustomerRecipientsDomainRequest = createAction(
  'USERS/FETCH_CUSTOMER_RECIPIENTS_DOMAIN_REQUEST'
);
export const fetchCustomerRecipientsDomainSuccess = createAction<DomainResponsePayload>(
  'USERS/FETCH_CUSTOMER_RECIPIENTS_DOMAIN_SUCCESS'
);
export const fetchCustomerRecipientsDomainFailure = createAction<ErrorObject>(
  'USERS/FETCH_CUSTOMER_RECIPIENTS_DOMAIN_FAILURE'
);

export const fetchUserRolesCount = createAction('USERS/FETCH_ROLES_COUNT');
export const fetchUserRolesCountRequest = createAction('USERS/FETCH_ROLES_COUNT_REQUEST');
export const fetchUserRolesCountSuccess = createAction<API.Users.RolesCount>(
  'USERS/FETCH_ROLES_COUNT_SUCCESS'
);
export const fetchUserRolesCountFailure = createAction<ErrorObject>(
  'USERS/FETCH_ROLES_COUNT_FAILURE'
);

export const fetchUserIntegrationsCount = createAction('USERS/FETCH_INTEGRATION_COUNT');
export const fetchUserIntegrationsCountRequest = createAction(
  'USERS/FETCH_INTEGRATION_COUNT_REQUEST'
);
export const fetchUserIntegrationsCountSuccess = createAction<API.Users.IntegrationCount>(
  'USERS/FETCH_INTEGRATION_COUNT_SUCCESS'
);
export const fetchUserIntegrationsCountFailure = createAction<ErrorObject>(
  'USERS/FETCH_INTEGRATION_COUNT_FAILURE'
);

export const fetchTeamUsersCount = createAction('USERS/FETCH_TEAM_USERS_COUNT');
export const fetchTeamUsersCountRequest = createAction('USERS/FETCH_TEAM_USERS_COUNT_REQUEST');
export const fetchTeamUsersCountSuccess = createAction<API.Users.TeamUsersCount>(
  'USERS/FETCH_TEAM_USERS_COUNT_SUCCESS'
);
export const fetchTeamUsersCountFailure = createAction<ErrorObject>(
  'USERS/FETCH_TEAM_USERS_COUNT_FAILURE'
);

export const fetchUsersCount = createAction('USERS/FETCH_USERS_COUNT');
export const fetchUsersCountRequest = createAction('USERS/FETCH_USERS_COUNT_REQUEST');
export const fetchUsersCountSuccess = createAction<API.Users.Count>(
  'USERS/FETCH_USERS_COUNT_SUCCESS'
);
export const fetchUsersCountFailure = createAction<ErrorObject>('USERS/FETCH_USERS_COUNT_FAILURE');

export const fetchMetricCustomers = createAction('USERS/FETCH_METRIC_CUSTOMERS');
export const fetchMetricCustomersRequest = createAction('USERS/FETCH_METRIC_CUSTOMERS_REQUEST');
export const fetchMetricCustomersSuccess = createAction<API.Users.Customers>(
  'USERS/FETCH_METRIC_CUSTOMERS_SUCCESS'
);
export const fetchMetricCustomersFailure = createAction<ErrorObject>(
  'USERS/FETCH_METRIC_CUSTOMERS_FAILURE'
);
