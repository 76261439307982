import { UUID } from '@litlingo/react';
import {
  addNewLanguageMatcher,
  fetchIdentifierCustomers,
  setActiveLanguageMatcherId,
} from 'actions/identifier';
import CollapsableSection from 'components/CollapsableSection';
import LanguageMatcherSidebarList from 'components/LanguageMatcherRedesign/LanguageMatcherSidebarList';
import TestSentenceVisualizerSidebarIdentifiers from 'components/TestSentenceVisualizer/TestSentenceVisualizerSidebarIdentifiers';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getActiveLanguageMatcher,
  getIdentifierCustomers,
  getLanguageMatcherFromSelectedIdentifier,
  getLanguageMatcherTypes,
  getSelectedCustomerIdentifier,
  getSelectedIdentifierRevision,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { NormalizedIdentifier } from 'types';
import { v4 as uuidv4 } from 'uuid';
import IdentifierAssociatedModels from '../IdentifierAssociatedModels';
import IdentifierHeader from '../IdentifierHeader';
import IdentifierManagerUtilizationSection from './IdentifierManagerUtilizationSection';

type ComponentProps = {
  identifier: NormalizedIdentifier;
};

const IdentifierSidebar: React.FC<ComponentProps> = ({ identifier }) => {
  const dispatch = useDispatch();
  const [languageMatcherFilter, setLanguageMatcherFilter] = useState('');
  const activeLanguageMatcher = useSelector(getActiveLanguageMatcher);
  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const languageMatcherTypes = useSelector(getLanguageMatcherTypes);
  const languageMatchers = useSelector((state) =>
    getLanguageMatcherFromSelectedIdentifier(state, languageMatcherFilter.toUpperCase())
  );
  const customers = useSelector(getIdentifierCustomers);
  const selectedCustomer = useSelector(getSelectedCustomerIdentifier);

  useEffect(() => {
    dispatch(setActiveLanguageMatcherId(languageMatchers[0]?.uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (identifierRevision?.uuid) {
      dispatch(fetchIdentifierCustomers(identifierRevision.uuid));
    }
  }, [dispatch, identifierRevision?.uuid]);

  const handleLanguageMatcherClick = (languageMatcherId: UUID): void => {
    dispatch(setActiveLanguageMatcherId(languageMatcherId));
  };

  const addLocalLanguageMatcher = (): void => {
    if (!identifierRevision) return;
    const newMatcherId = uuidv4();

    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(languageMatcherTypes.adjacent_tokens).forEach(([key, field]) => {
      if (field != null && field.default) {
        defaultValues[key] = field.default;
      }
    });
    const matcherBody = {
      uuid: newMatcherId,
      name: `New ${languageMatchers.length + 1}`,
      saved: false,
      type: 'token',
      isNew: true,
      ...defaultValues,
    };

    dispatch(
      addNewLanguageMatcher({
        identifierId: identifierRevision.uuid,
        languageMatcherId: newMatcherId,
        matcherBody,
      })
    );
    dispatch(setActiveLanguageMatcherId(newMatcherId));
  };

  return (
    <>
      <div className="sticky top-0 z-100 shadow-header-shadow">
        <CollapsableSection
          headerTitle={identifier.name}
          headerClasses="w-full p-4 flex flex-row justify-between items-center text-heading-3 bg-litlingo-primary-120 border-l border-t border-litlingo-gray-1"
          titleClasses="text-white"
          iconColorClass="white"
          contentClasses="p-0"
        >
          <div className="flex flex-col">
            <IdentifierHeader />
            <IdentifierAssociatedModels />
          </div>
        </CollapsableSection>
      </div>
      <CollapsableSection
        headerTitle={`Utilization${
          selectedCustomer ? ` - ${customers.find((c) => c.uuid === selectedCustomer)?.name}` : ''
        }`}
        headerClasses="w-full p-4 flex flex-row justify-between items-center text-heading-3 bg-litlingo-primary-120 border-l border-litlingo-gray-1"
        titleClasses="text-white truncate"
        iconColorClass="white"
        contentClasses="p-0"
        isDefaultClosed
      >
        <div className="flex flex-col gap-4">
          <IdentifierManagerUtilizationSection />
        </div>
      </CollapsableSection>
      <CollapsableSection
        headerTitle="Matchers"
        headerClasses="w-full p-4 flex flex-row justify-between items-center text-heading-3 bg-litlingo-primary-120 border-l border-litlingo-gray-1"
        titleClasses="text-white"
        iconColorClass="white"
        contentClasses="p-0"
      >
        <LanguageMatcherSidebarList
          languageMatchers={languageMatchers}
          languageMatcherFilter={languageMatcherFilter}
          setLanguageMatcherFilter={setLanguageMatcherFilter}
          highlightedLanguageMatcher={activeLanguageMatcher?.uuid || ''}
          addLocalLanguageMatcher={addLocalLanguageMatcher}
          handleLanguageMatcherClick={handleLanguageMatcherClick}
        />
      </CollapsableSection>
      {/* <CollapsableSection
        headerTitle="Test"
        headerClasses="w-full p-4 flex flex-row justify-between items-center text-heading-3 bg-litlingo-primary-120 border-b border-l border-litlingo-gray-1"
        titleClasses="text-white"
        iconColorClass="white"
        isDefaultClosed
        contentClasses="p-0"
      >
        <TestPhraseSidebarSection />
      </CollapsableSection> */}
      <CollapsableSection
        headerTitle="Test"
        headerClasses="w-full p-4 flex flex-row justify-between items-center text-heading-3 bg-litlingo-primary-120 border-l border-litlingo-gray-1"
        titleClasses="text-white"
        iconColorClass="white"
        contentClasses="p-0"
      >
        <TestSentenceVisualizerSidebarIdentifiers identifierId={identifier.uuid} />
      </CollapsableSection>
    </>
  );
};

export default IdentifierSidebar;
