import React from 'react';

type ComponentProps = {
  className?: string;
  size?: string;
};

const LoadingIndicator: React.FC<ComponentProps> = (props) => {
  const { className, size } = props;

  return (
    <div
      data-testid="loader"
      className={`loader ease-linear rounded-full border-2 border-gray-200 h-${size || '5'} w-${
        size || '5'
      } ${className || ''}`}
    />
  );
};

export default LoadingIndicator;
