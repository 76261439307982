import { showSuccessAlert } from 'actions';
import {
  bulkUpsertKindModel,
  bulkUpsertKindModelFailure,
  bulkUpsertKindModelRequest,
  bulkUpsertKindModelSuccess,
  cloneModel,
  cloneModelFailure,
  cloneModelRequest,
  cloneModelSuccess,
  createModel,
  createModelFailure,
  createModelFulfill,
  createModelRequest,
  createModelSuccess,
  deleteModel,
  deleteModelFailure,
  deleteModelFulfill,
  deleteModelRequest,
  deleteModelSuccess,
  fetchAllModels,
  fetchAllModelsFailure,
  fetchAllModelsFulfill,
  fetchAllModelsRequest,
  fetchAllModelsSuccess,
  fetchModelCategories,
  fetchModelCategoriesFailure,
  fetchModelCategoriesFulfill,
  fetchModelCategoriesRequest,
  fetchModelCategoriesSuccess,
  fetchSingleModel,
  fetchSingleModelFailure,
  fetchSingleModelFulfill,
  fetchSingleModelRequest,
  fetchSingleModelSuccess,
  upsertModel,
  upsertModelCategory,
  upsertModelCategoryFailure,
  upsertModelCategoryFulfill,
  upsertModelCategoryRequest,
  upsertModelCategorySuccess,
  upsertModelFailure,
  upsertModelFulfill,
  upsertModelRequest,
  upsertModelSuccess,
} from 'actions/models';
import { apiClientV2 as LitLingoClientV2 } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import type { API, APIV2, RouteParams, SagaReturn } from 'types';
import { reverse } from 'utils/urls';
import { v4 as uuidv4 } from 'uuid';

function* fetchSingleModelSaga(action: ReturnType<typeof fetchSingleModel>): SagaReturn {
  const { payload } = action;
  yield put(fetchSingleModelRequest());
  const response = (yield call([LitLingoClientV2.resources.models, 'retrieve'], payload.modelId, {
    params: {
      include_pii: 'true',
    },
  })) as API.Response<APIV2.Models.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleModelFailure(response.error));
  } else {
    yield put(fetchSingleModelSuccess(response.data));
  }
  yield put(fetchSingleModelFulfill());
}

function* fetchModelCategoriesSaga(action: ReturnType<typeof fetchModelCategories>): SagaReturn {
  const { payload } = action;
  yield put(fetchModelCategoriesRequest({ modelId: payload.modelId }));
  const response = (yield call([LitLingoClientV2.resources.models.extras, 'getCategories'], {
    urlParams: {
      modelId: payload.modelId,
    },
    params: {
      relationships: ['rule', 'updated_by', 'customer', 'outcome.outcome', 'model'],
      include_pii: 'true',
    },
  })) as API.Response<APIV2.Models.GetCategories>;

  if (response.error != null) {
    yield put(fetchModelCategoriesFailure(response.error));
  } else {
    yield put(
      fetchModelCategoriesSuccess({ modelId: payload.modelId, categories: response.data.records })
    );
  }
  yield put(fetchModelCategoriesFulfill({ modelId: payload.modelId }));
}

function* fetchModelsList(paramsArg: Partial<RouteParams>): SagaReturn {
  yield put(fetchAllModelsRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.model)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = { ...paramsArg, include_count: true, ...resourceParams };

  const response = (yield call([LitLingoClientV2.resources.models, 'list'], {
    params,
  })) as API.Response<APIV2.Models.List>;
  if (response.error != null) {
    yield put(fetchAllModelsFailure(response.error));
  } else {
    yield put(fetchAllModelsSuccess(response.data));
  }
  yield put(fetchAllModelsFulfill());
}

function* fetchAllModelsSaga({ payload }: ReturnType<typeof fetchAllModels>): SagaReturn {
  const navParams = (yield select(getNavParams)) as RouteParams;
  const params = {
    ...navParams,
    include_pii: 'true',
    relationships: ['updated_by'],
    ...payload,
  };

  yield call(fetchModelsList, params);
}

function* createModelSaga({ payload }: ReturnType<typeof createModel>): SagaReturn {
  const modelData = {
    name: payload.name,
    description: payload.description,
    id: uuidv4(),
    priority: payload.priority,
  };

  yield put(createModelRequest());
  const response = (yield call([LitLingoClientV2.resources.models, 'upsert'], {
    data: modelData,
  })) as API.Response<APIV2.Models.Upsert>;

  if (response.error != null) {
    yield put(createModelFailure(response.error));
  } else {
    yield put(createModelSuccess(response.data));
    const customerDomain = 'global';
    const path = reverse({
      routeName: 'global-model-manager',
      routeParams: { modelId: response.data.uuid },
      customerDomain,
    });
    yield put(push(path));
  }
  yield put(createModelFulfill());
}

function* deleteModelSaga(action: ReturnType<typeof deleteModel>): SagaReturn {
  const { payload } = action;
  const { id } = payload;
  yield put(deleteModelRequest(id));

  const response = (yield call(
    [LitLingoClientV2.resources.models, 'delete'],
    id
  )) as API.Response<APIV2.Models.Delete>;

  if (response.error != null) {
    yield put(deleteModelFailure(response.error));
  } else {
    yield put(deleteModelSuccess(id));
    const customerDomain = 'global';
    const path = reverse({ routeName: 'global-models-list', customerDomain });
    yield put(push(path));
    // yield put(prepareUndeleteAlert({ resource: 'models', id, fetchAction: fetchAllModels }));
  }
  yield put(deleteModelFulfill());
}

function* upsertModelSaga(action: ReturnType<typeof upsertModel>): SagaReturn {
  const { payload } = action;

  yield put(upsertModelRequest());

  const response = (yield call([LitLingoClientV2.resources.models, 'upsert'], {
    data: payload,
  })) as API.Response<APIV2.Models.Upsert>;
  if (response.error != null) {
    yield put(upsertModelFailure(response.error));
  } else {
    yield put(showSuccessAlert('Saved Model'));
    yield put(upsertModelSuccess(response.data));

    if (payload.redirect) {
      const customerDomain = 'global';
      const path = reverse({
        routeName: 'global-model-manager',
        routeParams: { modelId: response.data.uuid },
        customerDomain,
      });
      yield put(push(path));
    }
  }

  yield put(upsertModelFulfill());
}

function* upsertCategorySaga(action: ReturnType<typeof upsertModelCategory>): SagaReturn {
  const { payload } = action;

  yield put(upsertModelCategoryRequest());

  const response = (yield call([LitLingoClientV2.resources.categories, 'upsert'], {
    data: payload,
    params: { relationships: ['customer', 'updated_by', 'outcome', 'rule', 'model'] },
  })) as API.Response<APIV2.Categories.Upsert>;
  if (response.error != null) {
    yield put(upsertModelCategoryFailure(response.error));
  } else {
    yield put(showSuccessAlert('Saved Category'));
    yield put(upsertModelCategorySuccess(response.data));

    if (payload.redirect) {
      const customerDomain = 'global';
      const path = reverse({
        routeName: 'global-category-manager',
        routeParams: { categoryId: response.data.uuid },
        customerDomain,
      });
      yield put(push(path));
    }
  }

  yield put(upsertModelCategoryFulfill());
}

function* bulkUpsertKindModelSaga(action: ReturnType<typeof bulkUpsertKindModel>): SagaReturn {
  const { payload } = action;
  const { modelIds, group } = payload;

  yield put(bulkUpsertKindModelRequest());

  const response = (yield call([LitLingoClientV2.resources.models.extras, 'bulkUpdateKind'], {
    data: { uuids: modelIds, kind: group },
  })) as API.Response<APIV2.Models.BulkUpdateKind>;
  if (response.error != null) {
    yield put(bulkUpsertKindModelFailure(response.error));
  } else {
    yield put(showSuccessAlert('Use Cases were moved to the new group'));
    yield put(bulkUpsertKindModelSuccess(response.data));
  }
}

function* cloneModelSaga({ payload }: ReturnType<typeof cloneModel>): SagaReturn {
  const { modelId, redirect = false } = payload;

  yield put(cloneModelRequest());

  const response = (yield call([LitLingoClientV2.resources.models.extras, 'cloneModel'], {
    urlParams: { modelId },
  })) as API.Response<APIV2.Models.CloneModel>;

  if (response.error != null) {
    yield put(cloneModelFailure(response.error));
  } else {
    yield put(cloneModelSuccess(response.data));
    yield put(showSuccessAlert('Model copied successfully'));
    if (redirect) {
      const customerDomain = 'global';
      const path = reverse({
        routeName: 'global-model-manager',
        routeParams: { modelId: response.data.uuid },
        customerDomain,
      });
      window.open(path, '_blank');
    }
  }
}

function* modelsSaga(): SagaReturn {
  yield takeLatest(fetchAllModels.toString(), fetchAllModelsSaga);
  yield takeLatest(upsertModel.toString(), upsertModelSaga);
  yield takeLatest(fetchSingleModel.toString(), fetchSingleModelSaga);
  yield takeLatest(fetchModelCategories.toString(), fetchModelCategoriesSaga);
  yield takeLatest(createModel.toString(), createModelSaga);
  yield takeLatest(deleteModel.toString(), deleteModelSaga);
  yield takeLatest(upsertModelCategory.toString(), upsertCategorySaga);
  yield takeLatest(bulkUpsertKindModel.toString(), bulkUpsertKindModelSaga);
  yield takeLatest(cloneModel.toString(), cloneModelSaga);
}

export default modelsSaga;
