import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';

import { saveCustomer } from 'actions';
import PromptsFilter from 'components/Filters/PromptsFilter';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getPrompts } from 'selectors/entities';
import { getNavParamsByResource } from 'selectors/nav';
import { getActiveTasksLoading } from 'selectors/tasks';
import { Prompt } from 'types';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import EntityTable from './EntityTable';

const EntityList: React.FC = () => {
  const dispatch = useDispatch();

  const [selectedPrompt, setSelectedPrompt] = React.useState<Prompt | undefined>(undefined);

  const loading = useSelector(getActiveTasksLoading);
  const user = useSelector(getUser);
  const filter = useSelector(getNavParamsByResource('entities')).prompt_uuid as string;
  const prompts = useSelector(getPrompts);

  // @ts-ignore
  const isOnEntityExtractConfig = user?.customer?.config?.entity_extraction_config?.some(
    // @ts-ignore
    (ec) => ec.prompt_uuid === selectedPrompt?.uuid
  );

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  useEffect(() => {
    if (filter) {
      setSelectedPrompt(prompts.find((p) => p.uuid === filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, prompts.length]);

  const handleAddRemoveEntityExtract = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      dispatch(
        saveCustomer({
          ...user?.customer,
          config: {
            ...user?.customer?.config,
            // @ts-ignore
            entity_extraction_config: [
              // @ts-ignore
              ...user?.customer?.config.entity_extraction_config.filter(
                // @ts-ignore
                (ec) => ec.prompt_uuid === selectedPrompt?.uuid
              ),
              {
                prompt_uuid: selectedPrompt?.uuid,
                // @ts-ignore
                entity_kind: selectedPrompt?.entity_kind,
                sample_size: 100,
              },
            ],
          },
        })
      );
    } else {
      dispatch(
        saveCustomer({
          ...user?.customer,
          config: {
            ...user?.customer?.config,
            // @ts-ignore
            entity_extraction_config: [
              // @ts-ignore
              ...user?.customer?.config.entity_extraction_config.filter(
                // @ts-ignore
                (ec) => ec.prompt_uuid !== selectedPrompt?.uuid
              ),
            ],
          },
        })
      );
    }
  };

  return (
    <div
      className="flex flex-col min-w-screen bg-white overflow-hidden"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className="pb-6 flex flex-col gap-2 h-full w-full overflow-hidden">
          <div className="flex flex-col gap-2 pt-6 px-10">
            <div className="flex flex-row items-center">
              <h2 className="text-heading-2">
                {
                  // @ts-ignore
                  selectedPrompt ? `Entities (${selectedPrompt.name})` : 'Entities'
                }
              </h2>
              {loading && <LoadingIndicator className="ml-2" size="5" />}
            </div>

            <div className="flex justify-between items-center">
              <div className="flex justify-between gap-4">
                <SearchInput
                  resource={resourceQueryParamName.entities}
                  field="name"
                  logEventMessage="entity-search"
                  className="h-8 max-w-60"
                  placeholder="Search entities"
                />
                {selectedPrompt && (
                  <label htmlFor="add-remove-entity" className="flex gap-2 items-center">
                    <input
                      id="add-remove-entity"
                      type="checkbox"
                      onChange={handleAddRemoveEntityExtract}
                      className="form-checkbox litlingo-checkbox"
                      checked={isOnEntityExtractConfig}
                    />
                    <span>Entity extract</span>
                  </label>
                )}
              </div>
              <PromptsFilter resource="entities" setSelectedPrompt={setSelectedPrompt} />
            </div>
          </div>

          <EntityTable />
        </div>
      </div>
    </div>
  );
};

export default EntityList;
