import { useEffect } from 'react';

// taken from: https://stackoverflow.com/a/42234988/8133868
function useClickOutside(
  ref:
    | React.MutableRefObject<HTMLDivElement | null>
    | React.MutableRefObject<HTMLDivElement | null>[],
  handler: () => void
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (Array.isArray(ref)) {
        // to handle things like React portals
        if (!ref.some((r) => r.current && r.current.contains(event.target as Node))) {
          handler();
        }
      } else if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
}

export default useClickOutside;
