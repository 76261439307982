import type { UpsertIdentifierPayload } from 'actions/identifier';
import {
  addNewLanguageMatcher,
  clearIdentifier,
  discardIdentifierChanges,
  fetchLanguageMatcherTypes,
  setActiveLanguageMatcherId,
  setHasChanges,
  upsertIdentifier,
} from 'actions/identifier';
import CollapsableMainSection from 'components/CollapsableCard/CollapsableMainSection';
import EmptyLanguageMatcherState from 'components/EmptyLanguageMatcherState';
import LeavePageModal from 'components/LeavePageModal';
import { matcherTypeLabelMap } from 'constants/annotator';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getActiveLanguageMatcher,
  getActiveLanguageMatcherId,
  getLanguageMatcherFromSelectedIdentifier,
  getLanguageMatcherTypes,
  getSelectedIdentifierRevision,
  unsavedChanges,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { UUID } from 'types';
import { v4 as uuidv4 } from 'uuid';
import AdjacentTokensForm from './AdjacentTokensForm';
import DeleteMatcherButton from './DeleteMatcherButton';
import LanguageMatcherForm from './LanguageMatcherForm';
import TokenTypeForm from './TokenTypeForm';

type LanguageMatcherType = {
  identifierData: UpsertIdentifierPayload;
};

const LanguageMatcherRedesign: React.FC<LanguageMatcherType> = ({ identifierData }) => {
  const dispatch = useDispatch();

  const [isSaved, setIsSaved] = useState(false);

  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const unsaved = useSelector(unsavedChanges);
  const activeLanguageMatcher = useSelector(getActiveLanguageMatcher);
  const activeLanguageMatcherId = useSelector(getActiveLanguageMatcherId);
  const languageMatcherTypes = useSelector(getLanguageMatcherTypes);
  const languageMatchers = useSelector((state) =>
    getLanguageMatcherFromSelectedIdentifier(state, '')
  );

  useEffect(() => {
    if (activeLanguageMatcher && activeLanguageMatcher.isNew) {
      const input = document.getElementById('name');
      input?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguageMatcherId]);

  useEffect(() => {
    dispatch(fetchLanguageMatcherTypes());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLanguageMatcherClick = (languageMatcherId: UUID): void => {
    dispatch(setActiveLanguageMatcherId(languageMatcherId));
  };

  const onSubmit = (): void => {
    if (!identifierRevision) return;

    dispatch(
      upsertIdentifier({
        ...identifierData,
        identifierId: identifierRevision.uuid,
        options_config: {},
      })
    );
    setIsSaved(true);
  };

  const addLocalLanguageMatcher = (): void => {
    if (!identifierRevision) return;
    const newMatcherId = uuidv4();

    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(languageMatcherTypes.adjacent_tokens).forEach(([key, field]) => {
      if (field != null && field.default) {
        defaultValues[key] = field.default;
      }
    });
    const matcherBody = {
      uuid: newMatcherId,
      name: `New ${languageMatchers.length + 1}`,
      saved: false,
      type: 'token',
      isNew: true,
      ...defaultValues,
    };

    dispatch(
      addNewLanguageMatcher({
        identifierId: identifierRevision.uuid,
        languageMatcherId: newMatcherId,
        matcherBody,
      })
    );
    handleLanguageMatcherClick(newMatcherId);
  };

  const renderLanguageMatcherForm = (): JSX.Element => {
    if (activeLanguageMatcher.type === 'token') {
      return <TokenTypeForm isSaved={isSaved} />;
    }
    if (activeLanguageMatcher.type === 'adjacent_tokens') {
      return <AdjacentTokensForm isSaved={isSaved} />;
    }
    return <LanguageMatcherForm isSaved={isSaved} />;
  };

  const saveButton = (
    <div className="h-8 flex flex-row items-end gap-2">
      {activeLanguageMatcher &&
        activeLanguageMatcher.type !== 'token' &&
        activeLanguageMatcher.type !== 'adjacent_tokens' && (
          <DeleteMatcherButton
            setValue={(): void => {
              // moot
            }}
            setHighlightedLanguageMatcher={(): void => {
              // moot
            }}
            activeLanguageMatcher={activeLanguageMatcher}
          />
        )}
      {activeLanguageMatcher && unsaved && (
        <button
          data-testid="save-matchers-button"
          type="button"
          className="button button--secondary h-full w-22 justify-center"
          onClick={(e): void => {
            dispatch(discardIdentifierChanges());
            e.stopPropagation();
          }}
        >
          <span className="font-bold">Discard</span>
        </button>
      )}
      <button
        data-testid="save-matchers-button"
        type="button"
        className="button button--primary h-full w-22 justify-center text-body"
        onClick={(e): void => {
          onSubmit();
          e.stopPropagation();
        }}
      >
        <span className="font-bold text-white">Save</span>
      </button>
    </div>
  );

  return (
    <CollapsableMainSection
      headerTitle={
        activeLanguageMatcher
          ? `${activeLanguageMatcher?.name} (${
              matcherTypeLabelMap[activeLanguageMatcher?.type as keyof typeof matcherTypeLabelMap]
            })`
          : 'Matcher'
      }
      customAction={saveButton}
      containerClasses="h-full"
    >
      <div className="w-full min-h-104 h-full flex flex-col justify-center overflow-visible">
        <div
          className={`flex flex-col flex-1 overflow-y-auto pb-3 ${
            !activeLanguageMatcher && 'justify-center'
          }`}
        >
          {!activeLanguageMatcher ? (
            <EmptyLanguageMatcherState addNewLanguageMatcher={addLocalLanguageMatcher} />
          ) : (
            renderLanguageMatcherForm()
          )}
        </div>

        <LeavePageModal
          shouldBlockNavigation={unsaved}
          dicardAction={(): void => {
            dispatch(setHasChanges(false));
            dispatch(clearIdentifier());
          }}
        />
      </div>
    </CollapsableMainSection>
  );
};

export default LanguageMatcherRedesign;
