import { editLanguageMatcher, fetchLanguageMatcherTypes } from 'actions/identifier';
import WarningMsg from 'components/WarningMsg';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getActiveLanguageMatcher,
  getActiveLanguageMatcherId,
  getOriginalActiveLanguageMatcher,
  saveIdentifierRequestLoading,
  unsavedChanges,
} from 'selectors/identifier';
import { useSelector } from 'store';
import UserNotificationMsgs from 'utils/userNotificationMsgs';
import ChooseCombinationType from './ChooseCombinationType';

type LanguageMatcherType = {
  isSaved: boolean;
};

const LanguageMatcherForm: React.FC<LanguageMatcherType> = ({ isSaved }) => {
  const dispatch = useDispatch();

  const unsaved = useSelector(unsavedChanges);
  const activeLanguageMatcher = useSelector(getActiveLanguageMatcher);
  const originalActiveLanguageMatcher = useSelector(getOriginalActiveLanguageMatcher);
  const activeLanguageMatcherId = useSelector(getActiveLanguageMatcherId);
  const isLoadingSaveIdentifier = useSelector(saveIdentifierRequestLoading);

  useEffect(() => {
    if (activeLanguageMatcher && activeLanguageMatcher.isNew) {
      const input = document.getElementById('name');
      input?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguageMatcherId]);

  useEffect(() => {
    dispatch(fetchLanguageMatcherTypes());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onEditLanguageMatcher = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!activeLanguageMatcher.uuid || e.target.value === null) return;
    dispatch(
      editLanguageMatcher({
        languageMatcherId: activeLanguageMatcher.uuid,
        data: { [e.target.name]: e.target.value },
      })
    );
  };

  return (
    <>
      <div className="pt-4 px-6 flex flex-row justify-between">
        <div />
        <WarningMsg
          message={UserNotificationMsgs.unsavedChanges}
          showLoading={isLoadingSaveIdentifier}
          showWarning={unsaved}
          showSavedMsg={isSaved}
        />
      </div>
      <div className="grid gap-x-4 grid-cols-2 px-6 pt-6">
        <label htmlFor="name" className="flex flex-col gap-1 text-body">
          <div className="flex flex-row">
            <span>Name</span>
            <span className="text-litlingo-alert">*</span>
          </div>
          <input
            name="name"
            id="name"
            data-testid="matcher-name-input"
            value={activeLanguageMatcher.name}
            className={`form-input w-full py-2 px-4 border border-litlingo-gray-2 rounded-lg text-body focus:outline-none ${
              activeLanguageMatcher.name !== originalActiveLanguageMatcher?.name
                ? 'bg-litlingo-focus-area-background'
                : ''
            }`}
            onChange={onEditLanguageMatcher}
          />
        </label>
      </div>
      <div className="flex pt-4 px-6 justify-between">
        <div className="w-full">
          <ChooseCombinationType activeLanguageMatcher={activeLanguageMatcher} />
        </div>
      </div>
    </>
  );
};

export default LanguageMatcherForm;
