/* eslint-disable max-lines */

import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import { getTagGroupsForSavedSearch } from 'selectors/tagGroup';

import { SavedSearch, TagGroup, User } from '@litlingo/client';
import {
  addUserToSavedSearch,
  deleteSavedSearch,
  fetchSingleSavedSearch,
  removeUserFromSavedSearch,
  upsertSavedSearch,
} from 'actions';
import { MultiSelectOption } from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import DigestUserTable from 'components/SavedSearchesManager/Sidebar/DigestUserTable';
import SelectRedesign from 'components/Select/SelectRedesign';
import TagGroupLabels from 'components/TagGroups/TagGroupsLabels';
import TagGroupsSelect from 'components/TagGroups/TagGroupsSelect';
import useClickOutside from 'components/utils/useClickOutside';
import { PLUS_ICON } from 'constants/envelopeIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { reviewPriorityOptions } from 'constants/reviewSets';
import { getUser } from 'selectors/auth';
import getEnvelopeReviewLabelOptions from 'selectors/reviewStatus';
import { useSelector } from 'store';
import { RouteParams } from 'types';
import { getParamsFromUrl } from 'utils/urls';
import FilterSection from './FilterSection';

const FilterSectionMemo = React.memo(FilterSection);

type TagGroupsType = {
  tagGroups: TagGroup[];
  handleSelectTagGroup: (group: TagGroup, checked: boolean) => void;
  handleDeleteTagGroup: (group: TagGroup) => void;
  setTagGroups: React.Dispatch<React.SetStateAction<TagGroup[]>>;
};

export const TagGroups: React.FC<TagGroupsType> = ({
  tagGroups,
  handleDeleteTagGroup,
  handleSelectTagGroup,
  setTagGroups,
}) => {
  const dispatch = useDispatch();

  const reviewSet = useSelector(getSelectedReviewSet);
  const tGroups = useSelector((state) => getTagGroupsForSavedSearch(state, reviewSet?.uuid || ''));

  const [init, setInit] = useState(false);
  const [showTagGroupsSelect, setShowTagGroupsSelect] = useState(false);
  const tagGroupRef = useRef<HTMLDivElement>(null);
  const handleToggleShowTagGroups = (): void => {
    setShowTagGroupsSelect(!showTagGroupsSelect);
  };
  useClickOutside(tagGroupRef, handleToggleShowTagGroups);

  useEffect(() => {
    if (!init && tGroups.length > 0) {
      setTagGroups(tGroups);
      setInit(true);
    }
  }, [dispatch, tGroups, init, setTagGroups]);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row justify-between items-center">
        <span className="font-bold">Tag Groups</span>
        <div className="relative flex flex-col">
          <button
            type="button"
            className="px-1 py-0.5 w-26 flex flex-row justify-between items-center gap-1 bg-litlingo-gray-0.5 border border-litlingo-gray-3 rounded text-small focus:outline-none"
            onClick={handleToggleShowTagGroups}
          >
            <span className="w-3 h-3">{PLUS_ICON}</span>
            <span>Add tag group</span>
          </button>
          {showTagGroupsSelect && (
            <div className="absolute top-full right-0 mt-1" ref={tagGroupRef}>
              <TagGroupsSelect
                selectTagGroup={handleSelectTagGroup}
                selectedTagGroups={tagGroups}
                className="min-w-44"
                defaultOpened
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-row flex-wrap">
        {tagGroups.length > 0 ? (
          <TagGroupLabels tagGroups={tagGroups} handleDelete={handleDeleteTagGroup} />
        ) : (
          'No tag groups'
        )}
      </div>
    </div>
  );
};

type ComponentProps = {
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditSidebar: React.FC<ComponentProps> = ({ setEditing }) => {
  const dispatch = useDispatch();

  const reviewSet = useSelector(getSelectedReviewSet);
  const currentUser = useSelector(getUser);
  const reviewLabelOptions = useSelector(getEnvelopeReviewLabelOptions);

  const [reviewPriority, setReviewPriority] = useState<SavedSearch['priority'] | ''>(
    reviewSet?.priority || ''
  );
  const [reviewStatus, setReviewStatus] = useState<MultiSelectOption[]>([]);

  const [users, setUsers] = useState(reviewSet?.users as User[]);
  const [tagGroups, setTagGroups] = useState([] as TagGroup[]);

  const [errors, setErrors] = useState<Record<string, string>>();

  const { register, getValues, handleSubmit } = useForm<{
    name: string;
    description: string;
    size: number;
  }>({
    defaultValues: {
      size: reviewSet?.config?.default_count || 100,
      name: reviewSet?.name,
      description: reviewSet?.description,
    },
  });

  useEffect(() => {
    const params =
      currentUser.customer &&
      getParamsFromUrl(
        reviewSet?.url || '',
        resourceQueryParamName.envelopes,
        'envelope-list',
        currentUser.customer?.config
      );

    if (params && params.review_values) {
      const reviewValues = params.review_values as string[];
      const reviewStatusDefault = Object.entries(reviewLabelOptions)
        .filter(([v]) => v !== 'skipped' && reviewValues.some((r) => r === v))
        .map(([value, label]) => ({
          value,
          label,
        }));

      setReviewStatus(reviewStatusDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, reviewSet]);

  const onSubmit = (): void => {
    const { name, description, size } = getValues();

    if (reviewStatus.length === 0) {
      setErrors({ reviewStatus: 'You must select at least one review stats' });
      return;
    }

    if (reviewPriority === '') {
      setErrors({ priority: 'You must select a priority' });
      return;
    }

    setErrors({});

    let params: RouteParams = {};

    if (currentUser.customer && reviewSet) {
      params = getParamsFromUrl(
        reviewSet.url,
        resourceQueryParamName.envelopes,
        'envelope-list',
        currentUser.customer.config
      );
    }

    const urlParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (!key.includes('review_values')) {
        if (Array.isArray(value)) {
          value.forEach((v) => urlParams.append(`envelopes__${key}`, v));
        } else {
          urlParams.append(`envelopes__${key}`, value);
        }
      }
    });

    const urlQuery = `?${urlParams.toString()}`;
    const paramCount = Object.keys(params).length;

    if (reviewSet) {
      dispatch(
        upsertSavedSearch({
          ...reviewSet,
          name,
          description,
          priority: reviewPriority,
          url: urlQuery,
          param_count: paramCount,
          /* FRO-1090 cadence: reviewCadence, */
          config: {
            default_count: Math.round(size) || -1,
            tag_values_groups_uuids: tagGroups.map((g) => g.uuid),
          },
        })
      );
      setEditing(false);
    }
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const handleDeleteTagGroup = (group: TagGroup): void => {
    setTagGroups(tagGroups.filter((g) => g.uuid !== group.uuid));
  };

  const handleSelectTagGroup = (group: TagGroup, checked: boolean): void => {
    if (checked) {
      setTagGroups([...tagGroups, group]);
    } else {
      handleDeleteTagGroup(group);
    }
  };
  const handleDeleteUser = (user: User): void => {
    setUsers(users.filter((u) => u.uuid !== user.uuid));
    if (reviewSet) {
      dispatch(removeUserFromSavedSearch({ user, savedSearchId: reviewSet.uuid }));
    }
  };

  const handleSelectUser = (user: User, checked: boolean): void => {
    if (checked) {
      setUsers((userList) => [...userList, user]);
      if (reviewSet) {
        dispatch(addUserToSavedSearch({ user, savedSearchId: reviewSet.uuid }));
      }
    } else {
      handleDeleteUser(user);
    }
  };

  const handleDelete = (): void => {
    if (reviewSet) {
      dispatch(deleteSavedSearch({ uuid: reviewSet?.uuid }));
      setEditing(false);
    }
  };

  return (
    <div className="flex flex-col justify-between h-full w-full bg-white">
      <div className="w-full h-full px-8 pt-6 pb-10 flex flex-col justify-between gap-40 bg-white overflow-auto no-scrollbar">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4 text-body">
            <div className="flex flex-row justify-between items-center">
              <label htmlFor="name" className="flex flex-col gap-1">
                <input
                  id="name"
                  ref={register({ required: true, pattern: /\S/i })}
                  name="name"
                  placeholder="New save search"
                  className="form-input h-9 w-64 p-2 font-bold rounded placeholder-italic placeholder-litlingo-gray-6"
                />
              </label>

              <div className="flex flex-row gap-2">
                <button
                  type="button"
                  className="button button--secondary h-8 w-20 p-0"
                  onClick={(): void => {
                    setEditing(false);
                    dispatch(fetchSingleSavedSearch({ uuid: reviewSet?.uuid || '' }));
                  }}
                >
                  <span className="flex items-center justify-center font-bold h-full w-full">
                    Cancel
                  </span>
                </button>

                <button
                  type="button"
                  className="button button--primary h-8 w-20 p-0 text-body"
                  onClick={handleSaveButton}
                >
                  <span className="flex items-center justify-center text-white font-bold h-full w-full">
                    Save
                  </span>
                </button>
              </div>
            </div>

            <label htmlFor="description" className="flex flex-col gap-1">
              <div className="flex flex-row gap-1">
                <span>Description</span>
              </div>

              <textarea
                id="description"
                ref={register()}
                name="description"
                rows={3}
                placeholder="A short description that can help others understand the purpose of this tag group"
                className="form-input w-full p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
              />
            </label>

            <div className="flex flex-col gap-1">
              <div className="flex flex-row gap-1">
                <span>Priority</span>
                <span className="litlingo-red-color">*</span>
              </div>

              <div className="flex-shrink-0 w-44">
                <SelectRedesign
                  onChange={(value: string): void =>
                    setReviewPriority(value as SavedSearch['priority'])
                  }
                  options={reviewPriorityOptions}
                  value={reviewPriority || ''}
                  placeholder="Select Priority"
                />
                <div className="text-small">
                  <span className="text-litlingo-alert">{errors?.priority}</span>
                </div>
              </div>
            </div>
          </div>

          <FilterSectionMemo />

          <div className="flex flex-col pt-2 gap-2">
            <TagGroups
              tagGroups={tagGroups}
              handleSelectTagGroup={handleSelectTagGroup}
              handleDeleteTagGroup={handleDeleteTagGroup}
              setTagGroups={setTagGroups}
            />

            {reviewSet && (
              <DigestUserTable
                reviewStream={reviewSet}
                handleSelectUser={handleSelectUser}
                users={users}
                handleDeleteUser={handleDeleteUser}
                allowDelete
              />
            )}
          </div>
        </div>
      </div>
      <button
        type="button"
        className="button button--error min-h-8 w-20 p-0 text-body mx-8 my-6 self-end"
        onClick={(): void => handleDelete()}
      >
        <span className="flex items-center justify-center text-litlingo-alert font-bold h-full w-full hover:text-white">
          Delete
        </span>
      </button>
    </div>
  );
};

export default EditSidebar;
