/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ResponsiveLine } from '@nivo/line';
import React, { useState } from 'react';
import { getEnvelopesAggs, getExperiments } from 'selectors/envelopes';
import { useSelector } from 'store';
import ModelMetricsCardsSection, { ModelMetricsType } from './ModelMetricsCardsSection';
import ModelMetricsFilters from './ModelMetricsFilters';
import ModelsMetricsHeader from './ModelsMetricsHeader';

const ModelsMetricsContent: React.FC = () => {
  const aggs = useSelector(getEnvelopesAggs);
  const metrics = useSelector(getExperiments);

  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [compareMode, setCompareMode] = useState(false);
  const [performanceFn, setPerformanceFn] =
    useState<(a: ModelMetricsType, b: ModelMetricsType) => number>();

  const handleCompare = (): void => {
    if (selectedModels.length === 2) {
      setCompareMode(true);
    }
  };

  const handleClearSelection = (): void => {
    setCompareMode(false);
    setSelectedModels([]);
  };

  const generateData = (
    numDays: number
  ): {
    x: string;
    y: number;
  }[] => {
    const data = [];
    const today = new Date();

    for (let i = 0; i < numDays; i += 7) {
      const date = new Date(today);
      date.setDate(today.getDate() - 90 + i);

      const formattedDate = date.toISOString().slice(0, 10); // Format as YYYY-MM-DD

      data.push({
        x: formattedDate,
        y: i + Math.floor(Math.random() * 10),
      });
    }

    return data;
  };

  return (
    <div className="w-full h-full pb-6 px-6 flex flex-col gap-3 overflow-auto pt-6">
      <ModelsMetricsHeader />
      <div className="w-full h-full flex flex-row">
        <div className="flex flex-col flex-grow gap-4">
          <ModelMetricsFilters resource="envelopes" setPerformanceFn={setPerformanceFn} />
          <div className="flex flex-col">
            {metrics.map((metric) => (
              <ModelMetricsCardsSection
                key={JSON.stringify(metric)}
                // @ts-ignore
                exp={metric}
                aggs={aggs}
                selectedModels={selectedModels}
                setSelectedModels={setSelectedModels}
                compareMode={compareMode ? 'left' : ''}
                performanceFn={performanceFn}
              />
            ))}
          </div>
          {compareMode && (
            <div className="min-h-80">
              <ResponsiveLine
                curve="linear"
                colors={[
                  // greens dark to light
                  '#65A782',
                  '#93C6A8',
                  '#B6DDC6',
                  // yellows dark to light
                  '#F4A21F',
                  '#FFCB48',
                  '#FFEDA1',
                ]}
                pointSize={8}
                enableArea={false}
                legends={[
                  {
                    // dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    itemWidth: 100,
                    itemHeight: 20,
                    translateX: 130,
                    toggleSerie: true,
                  },
                ]}
                animate
                enableSlices="x"
                margin={{ top: 10, right: 220, bottom: 50, left: 50 }}
                axisLeft={{
                  format: (value): string => value,
                }}
                data={[
                  {
                    id: 'Antitrust (Higher Risk)',
                    color: 'hsl(75, 70%, 50%)',
                    data: generateData(90),
                  },
                  {
                    id: 'LLM Antitrust (HR) (GPT35)',
                    color: 'hsl(208, 70%, 50%)',
                    data: generateData(90),
                  },
                ]}
                axisBottom={{
                  tickRotation: -45,
                  legendOffset: 30,
                  tickPadding: 5,
                }}
              />
            </div>
          )}
        </div>
        <div className="w-1/2 h-full pl-6 flex flex-col items-center" data-testid="compare-section">
          {compareMode && (
            <div className="flex flex-col pt-22">
              {metrics.map((metric) => (
                <ModelMetricsCardsSection
                  key={JSON.stringify(metric)}
                  // @ts-ignore
                  exp={metric}
                  aggs={aggs}
                  selectedModels={selectedModels}
                  setSelectedModels={setSelectedModels}
                  compareMode={compareMode ? 'right' : ''}
                  performanceFn={performanceFn}
                />
              ))}
            </div>
          )}

          {selectedModels.length === 2 && (
            <div
              className={`w-1/2 h-full pl-6 ${
                compareMode ? 'pt-4' : 'pt-80'
              } flex flex-col items-center`}
            >
              <div className="flex flex-row justify-center gap-2">
                <button
                  type="button"
                  className="button button--primary h-8 whitespace-no-wrap"
                  disabled={selectedModels.length !== 2 || !!compareMode}
                  onClick={handleCompare}
                >
                  Compare Performance
                </button>
                <button
                  type="button"
                  className="button button--secondary h-8 whitespace-no-wrap"
                  onClick={handleClearSelection}
                >
                  <span className="font-bold">Clear Selection</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModelsMetricsContent;
