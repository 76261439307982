import { AnyAction, Store } from '@reduxjs/toolkit';
import config from 'config';
import { createBrowserHistory } from 'history';
import { isEqual } from 'lodash';
import type { Selector, TypedUseSelectorHook } from 'react-redux';
import { useSelector as useReduxSelector } from 'react-redux';
import type { GlobalState } from 'reducers';
import createRootReducer from 'reducers';
import rootSaga from 'sagas';
import initStore from './store';

export const history = createBrowserHistory({
  basename:
    config.app.prNumber == null || config.app.prNumber === 'false'
      ? '/'
      : `/prs/${config.app.prNumber}/index.html`,
});

export const rootReducer = createRootReducer(history);

export const store = initStore(rootReducer, rootSaga, history);

export const useSelector: TypedUseSelectorHook<GlobalState> = useReduxSelector;

export const useDeepCompareSelector: TypedUseSelectorHook<GlobalState> = <T>(
  selector: Selector<GlobalState, T>,
  equalityFn?: (a: T, b: T) => boolean
): T => useReduxSelector(selector, equalityFn ?? isEqual);

export const preloadedStore = (
  preloadedState: Partial<GlobalState>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Store<any, AnyAction> & {
  dispatch: unknown;
} => initStore(rootReducer, rootSaga, history, preloadedState);

export { rootSaga };
