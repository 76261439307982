import { changeScopeIdentifier } from 'actions/identifier';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getIdentifierCustomers,
  getOriginalScopedCustomersMatcher,
  getScopedCustomersMatcher,
} from 'selectors/identifier';
import { useSelector } from 'store';
import { MLanguageMatcher } from 'types';

type ComponentProps = {
  languageMatcher: MLanguageMatcher;
};

const ScopesSection: React.FC<ComponentProps> = ({ languageMatcher }) => {
  const dispatch = useDispatch();

  const customers = useSelector(getIdentifierCustomers);
  const scopedCustomers = useSelector((state) =>
    getScopedCustomersMatcher(state, languageMatcher.uuid)
  );
  const originalScopedCustomers = useSelector((state) =>
    getOriginalScopedCustomersMatcher(state, languageMatcher.uuid)
  );

  const [showScope, setShowScope] = useState(false);

  const toggleShowScope = (): void => {
    setShowScope((s) => !s);
  };

  const handleScopeCustomer = (customer: string): void => {
    if (scopedCustomers.includes(customer)) {
      dispatch(
        changeScopeIdentifier({
          act: 'remove',
          customerId: customer,
          matcherId: languageMatcher.uuid,
        })
      );
    } else {
      dispatch(
        changeScopeIdentifier({
          act: 'add',
          customerId: customer,
          matcherId: languageMatcher.uuid,
        })
      );
    }
  };

  const handleSelectAll = (): void => {
    if (scopedCustomers.length === customers.length) {
      customers.forEach((customer) => {
        if (scopedCustomers.includes(customer.uuid)) {
          dispatch(
            changeScopeIdentifier({
              act: 'remove',
              customerId: customer.uuid,
              matcherId: languageMatcher.uuid,
            })
          );
        }
      });
    } else {
      customers.forEach((customer) => {
        if (!scopedCustomers.includes(customer.uuid)) {
          dispatch(
            changeScopeIdentifier({
              act: 'add',
              customerId: customer.uuid,
              matcherId: languageMatcher.uuid,
            })
          );
        }
      });
    }
  };

  return (
    <div
      className="relative w-full flex flex-row bg-white z-10 overflow-visible"
      style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
    >
      <div className="relative flex flex-col gap-2 justify-between cursor-pointer w-full overflow-visible">
        <div
          className="py-1 pl-4.5 pr-4 flex flex-row justify-between text-body bg-litlingo-gray-1 z-10"
          style={showScope ? { filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' } : {}}
        >
          <label className="flex flex-row items-center gap-1" htmlFor="select-all-workspaces">
            {showScope && (
              <input
                type="checkbox"
                id="select-all-workspaces"
                name="select-all-workspaces"
                className={`form-checkbox litlingo-checkbox ${
                  scopedCustomers.length > 0 && scopedCustomers.length < customers?.length
                    ? 'checkbox-select-all-partial'
                    : ''
                }`}
                onChange={handleSelectAll}
                checked={scopedCustomers.length === customers?.length}
              />
            )}
            <span>Workspaces</span>
          </label>
          <div className="flex flex-row items-center gap-2">
            <div className="flex flex-row items-center">
              <span
                className={`${
                  scopedCustomers.length !== originalScopedCustomers.length
                    ? 'bg-litlingo-highlight'
                    : ''
                }`}
              >
                {scopedCustomers.length !== originalScopedCustomers.length
                  ? `${scopedCustomers.length}-`
                  : ''}
                {originalScopedCustomers.length}
              </span>
              <span>/{customers?.length}</span>
            </div>
            <button
              type="button"
              className="focus:outline-none flex flex-row"
              data-testid="expand-workspaces-button"
              onClick={toggleShowScope}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ ...(showScope ? { transform: 'rotate(90deg)' } : {}) }}
              >
                <path
                  d="M2.08961 9.91604C2.1425 9.96937 2.21437 9.99955 2.28948 9.99999C2.32675 10.0003 2.3637 9.99307 2.39807 9.97864C2.43244 9.9642 2.4635 9.9429 2.48934 9.91604L7.20219 5.20319C7.22866 5.17707 7.24968 5.14595 7.26402 5.11164C7.27837 5.07733 7.28575 5.04051 7.28575 5.00333C7.28575 4.96614 7.27837 4.92932 7.26402 4.89501C7.24968 4.8607 7.22866 4.82958 7.20219 4.80346L2.48934 0.0896109C2.46343 0.0619091 2.43221 0.0397035 2.39754 0.0243155C2.36287 0.00892748 2.32545 0.000671647 2.28753 3.93014e-05C2.2496 -0.000593044 2.21193 0.00641102 2.17677 0.0206347C2.1416 0.0348583 2.10966 0.0560109 2.08283 0.0828334C2.05601 0.109656 2.03486 0.1416 2.02063 0.176766C2.00641 0.211931 1.99941 0.249598 2.00004 0.287526C2.00067 0.325453 2.00893 0.362866 2.02432 0.397537C2.0397 0.432209 2.06191 0.46343 2.08961 0.489344L6.6026 5.00333L2.08961 9.51631C2.06326 9.5425 2.04235 9.57364 2.02809 9.60794C2.01382 9.64224 2.00647 9.67903 2.00647 9.71618C2.00647 9.75333 2.01382 9.79011 2.02809 9.82441C2.04235 9.85871 2.06326 9.88985 2.08961 9.91604Z"
                  fill="#333333"
                />
              </svg>
            </button>
          </div>
        </div>

        {showScope && (
          <div className="absolute w-full left-0 top-full flex flex-col gap-2 pt-2 pb-3 bg-white">
            {customers.map((customer) => (
              <div key={customer.uuid} className={`pl-4.5 pr-4 flex flex-row `}>
                <label className="w-full flex flex-row gap-1" htmlFor={`customer-${customer.uuid}`}>
                  <input
                    type="checkbox"
                    id={`customer-${customer.uuid}`}
                    className="form-checkbox litlingo-checkbox h-4 w-4"
                    onChange={(): void => handleScopeCustomer(customer.uuid)}
                    checked={scopedCustomers.includes(customer.uuid)}
                  />
                  <div
                    className={`pr-1 w-full flex flex-row justify-between items-center text-small ${
                      scopedCustomers.includes(customer.uuid) &&
                      !originalScopedCustomers.includes(customer.uuid)
                        ? 'bg-litlingo-success-light'
                        : ''
                    } ${
                      !scopedCustomers.includes(customer.uuid) &&
                      originalScopedCustomers.includes(customer.uuid)
                        ? 'bg-litlingo-alert-light'
                        : ''
                    }`}
                  >
                    <span>{customer.name}</span>

                    {/* {((scopedCustomers.includes(customer.uuid) &&
                      !originalScopedCustomers.includes(customer.uuid)) ||
                      (!scopedCustomers.includes(customer.uuid) &&
                        originalScopedCustomers.includes(customer.uuid))) && (
                      <button type="button" className="focus:outline-none">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 12C9.31371 12 12 9.31371 12 6C12 2.6863 9.31371 0 6 0C2.6863 0 0 2.6863 0 6C0 9.31371 2.6863 12 6 12Z"
                            fill="#ADADAD"
                          />
                          <path
                            d="M3.84473 3.84619L8.15368 8.15515"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.15368 3.84619L3.84473 8.15515"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )} */}
                  </div>
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScopesSection;
