import { saveCustomer } from 'actions';
import Permissions from 'components/Permissions';
import ToggleButton from 'components/ToggleButton';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import { useDispatch, useSelector } from 'react-redux';
import { getCustomer, getUser } from 'selectors/auth';
import { isValidDate } from 'utils/dateValidation';
import { isActionAuthorized } from 'utils/permissions';

const CustomerData: React.FC = () => {
  const dispatch = useDispatch();
  const customer = useSelector(getCustomer);
  const user = useSelector(getUser);

  const [storeCommunications, setStoreCommunications] = useState(
    customer.config.store_communications
  );
  const [storeDocument, setStoreDocument] = useState(customer.config.store_communications);
  const [communcationRetentionDays, setCommuncationRetentionDays] = useState(
    customer.config?.communication_retention_days || 0
  );
  const [syncStartDate, setSyncStartDate] = useState<string | null>(
    customer.config?.sync_start_date || moment.utc().format()
  );

  const handleSave = (): void => {
    dispatch(
      saveCustomer({
        config: {
          ...customer.config,
          store_communications: storeCommunications,
          store_document: storeDocument,
          communication_retention_days: communcationRetentionDays,
          sync_start_date: syncStartDate,
        },
      })
    );
  };

  const handleCancel = (): void => {
    setStoreCommunications(customer.config.store_communications);
    setStoreDocument(customer.config.store_communications);
    setCommuncationRetentionDays(customer.config?.communication_retention_days || 0);
    setSyncStartDate(customer.config?.sync_start_date || moment.utc().format());
  };

  const storeMessageBody = (
    <div className="p-4 flex justify-between items-start">
      <div className="max-w-156 flex flex-col gap-1">
        <span className="flex items-center text-body">Store message body</span>
        <p className="text-small">
          Improves performance by allowing LitLingo to store a copy of your messages. If disabled,
          the system will make a new request each time a message is processed or viewed.
        </p>
      </div>

      <ToggleButton
        dataTestid="store-message-body-button"
        isOn={storeDocument}
        disabled={!user.roles.includes('super-admin')}
        handleToggleClick={(): void => {
          setStoreDocument((s) => !s);
        }}
      />
    </div>
  );

  const messageBodyRetention = (
    <div className="p-4 flex justify-between items-center">
      <span className="flex items-center text-body">Message body retention</span>
      {user.roles.includes('super-admin') ? (
        <div className="flex flex-row gap-2 items-center">
          <input
            data-testid="message-body-retention-input"
            className="form-textarea text-body text-center h-6 w-10 px-1"
            id="retention"
            name="retention"
            value={communcationRetentionDays}
            onChange={(e): void =>
              setCommuncationRetentionDays(Number.parseInt(e.target.value, 10))
            }
            type="number"
          />
          <span>Days</span>
        </div>
      ) : (
        <div className="flex flex-row items-center text-body">
          <span>{`${customer.config?.communication_retention_days || 0} Days`}</span>
        </div>
      )}
    </div>
  );

  const messagesSyncedAsOf = (
    <div className="p-4 flex justify-between">
      <span className="flex items-center text-body">Messages synced as of</span>
      <div className="flex flex-row items-center">
        <DatePicker
          data-testid="data-picker-input"
          className="date-picker text-body text-right bg-white"
          popperPlacement="bottom-end"
          disabled={!user.roles.includes('super-admin')}
          selected={
            syncStartDate != null && isValidDate(syncStartDate)
              ? new Date(syncStartDate)
              : new Date()
          }
          onChange={(date): void => {
            const valueDate = moment.utc(date as Date).format();
            setSyncStartDate(valueDate);
          }}
        />
      </div>
    </div>
  );

  const hasChanges = (): boolean => {
    if (customer.config.store_communications !== storeCommunications) return true;
    if (customer.config.store_document !== storeDocument) return true;
    if (customer.config.communication_retention_days !== communcationRetentionDays) return true;
    if (customer.config.sync_start_date !== syncStartDate) return true;

    return false;
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2 justify-between items-center">
        <div className="flex flex-row gap-2 items-base">
          <p className="text-menu-item">Data</p>
          <p className="text-small">Contact LitLingo Support to make changes</p>
        </div>
        {isActionAuthorized}
        <Permissions action="customers.advancedOptions">
          <div className="flex flex-row gap-2">
            <button
              type="button"
              className="button button--secondary flex justify-center w-20 h-8"
              onClick={handleCancel}
            >
              <span className="font-bold">Cancel</span>
            </button>
            <button
              data-testid="save-button"
              type="button"
              className="button button--primary flex justify-center w-20 h-8"
              onClick={handleSave}
              disabled={!hasChanges()}
            >
              <span>Save</span>
            </button>
          </div>
        </Permissions>
      </div>
      <div className="flex flex-col bg-white border border-litlingo-gray-2 rounded">
        <div>{storeMessageBody}</div>
        <div className="border-t border-litlingo-gray-2">{messageBodyRetention}</div>
        <div className="border-t border-litlingo-gray-2">{messagesSyncedAsOf}</div>
      </div>
    </div>
  );
};

export default CustomerData;
