import { changeLanguageMatcherType } from 'actions/identifier';
import { matcherTypeLabelMap } from 'constants/annotator';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageMatcherTypes } from 'selectors/identifier';
import type { LanguageMatcher, LanguageMatchersTypes, MLanguageMatcher } from 'types';
import AddLanguageMatcherInput from './AddLanguageMatcherInput';

type ChooseCombinationTypeProps = {
  activeLanguageMatcher: MLanguageMatcher;
  excludedFields?: string[];
};

const ChooseCombinationType: React.FC<ChooseCombinationTypeProps> = (props) => {
  const combinations = useSelector(getLanguageMatcherTypes);
  const { activeLanguageMatcher: languageMatcher, excludedFields = [] } = props;
  const dispatch = useDispatch();

  const [combinationSelection, setCombinationSelection] = useState<string>('');

  useEffect(() => {
    if (!languageMatcher) return;
    if (languageMatcher.type) {
      setCombinationSelection(languageMatcher.type);
    }
  }, [languageMatcher]);

  const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setCombinationSelection(e.target.value);
    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(combinations[e.target.value as keyof typeof combinations]).forEach(
      ([key, field]) => {
        if (field != null && field.default) {
          defaultValues[key] = field.default;
        }
      }
    );
    dispatch(
      changeLanguageMatcherType({
        languageMatcherId: languageMatcher.uuid,
        type: e.target.value,
        ...defaultValues,
      })
    );
  };

  const isLanguageMatcherNew = !languageMatcher || (languageMatcher && languageMatcher.isNew);

  return (
    <div className="grid gap-y-4 gap-x-4 grid-cols-2">
      {!excludedFields.includes('type') && (
        <label htmlFor="keyword" className="flex flex-col gap-1 text-body">
          <div>Type</div>
          {!isLanguageMatcherNew ? (
            <div className="py-2 px-4 w-full bg-litlingo-gray-0.5 border border-litlingo-gray-2 rounded-lg">
              {matcherTypeLabelMap[combinationSelection as keyof typeof matcherTypeLabelMap]}
            </div>
          ) : (
            <div>
              <select
                value={combinationSelection}
                onChange={handleChangeType}
                id="Mode Of Speech"
                data-testid="select-language-matcher-type"
                className="block form-select w-full border border-litlingo-gray-2 disabled:bg-litlingo-gray-2"
                disabled={!isLanguageMatcherNew}
              >
                {Object.keys(combinations).map((combination) => (
                  <option key={combination} value={combination}>
                    {combination}
                  </option>
                ))}
              </select>
              {combinationSelection === 'keyword' ? (
                <span className="text-xs">WARNING: Please Do Not use!!</span>
              ) : (
                ''
              )}
              <p className="mt-2 text-sm text-gray-500">
                {combinations[combinationSelection as keyof typeof combinations] &&
                  combinations[combinationSelection as keyof typeof combinations].description}
              </p>
            </div>
          )}
        </label>
      )}
      {combinationSelection &&
        combinations[combinationSelection as keyof typeof combinations] &&
        Object.keys(combinations[combinationSelection as keyof typeof combinations])
          .filter((key) => !excludedFields.includes(key))
          .map((key) => (
            <AddLanguageMatcherInput
              key={key}
              form={key as keyof LanguageMatcher}
              languageMatcherId={languageMatcher?.uuid}
              type={combinationSelection as keyof LanguageMatchersTypes}
            />
          ))}
    </div>
  );
};

export default ChooseCombinationType;
