import React, { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import useClickOutside from '../utils/useClickOutside';

type ComponentProps = {
  className: string;
  onChange: (keyword?: string | boolean | undefined) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  options: {
    value: string | number;
    label: string | number;
  }[];
  selectedItems: (string | number)[];
  dataTestid: string;
};

const AnnotatorMultiSelectDropdown: React.FC<ComponentProps> = (props) => {
  const { className, onChange, isOpen, setIsOpen, options, selectedItems, dataTestid } = props;

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside([wrapperRef, dropdownRef], handleClickOutside);

  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(wrapperRef.current, popperRef, {
    placement: 'bottom-end',
  });

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const renderDropdown = (): React.ReactPortal | null => {
    const portal = document.getElementById('dropdown-portal');

    const dropdownElement = (
      <div
        className="relative"
        ref={(node): void => {
          setPopperRef(node);
        }}
        style={{ ...styles.popper, width: wrapperRef.current?.clientWidth }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes.popper}
      >
        <div
          className="my-1 border border-litlingo-gray-1 rounded-md bg-white overflow-hidden"
          id="multi-select-dropdown-content"
          ref={dropdownRef}
        >
          <div
            className="overflow-hidden"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="p-2 max-h-filter overflow-auto custom-scrollbar">
              <fieldset data-testid={`${dataTestid}-options`} className="flex flex-col gap-3">
                {options.map((option) => (
                  <div key={option.value} className="flex flex-row items-center gap-2">
                    <input
                      id={option.value.toString()}
                      data-testid={`${option.value}-checkbox`}
                      type="checkbox"
                      value={option.value}
                      className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
                      onChange={(e): void => onChange(e.target.value)}
                      checked={selectedItems.includes(option.value)}
                    />
                    <div className="text-body">
                      <label htmlFor={option.value.toString()}>{option.label}</label>
                    </div>
                  </div>
                ))}
                {options.length === 0 && (
                  <div className="text-center">
                    <span className="text-body">No items</span>
                  </div>
                )}
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    );

    if (portal) {
      return ReactDOM.createPortal(dropdownElement, portal);
    }
    return null;
  };

  return (
    <div ref={wrapperRef} className={`flex w-full bg-white ${className || ''}`}>
      <div className="w-full rounded">
        <button
          type="button"
          data-testid={`${dataTestid}-button`}
          // hover:font-bold
          className="w-full py-2 px-4 flex justify-between items-center text-body border border-litlingo-gray-2 rounded-lg focus:outline-none disabled:opacity-50"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={(e): void => handleButtonClick(e)}
        >
          <div className="italic">{`Selected ${
            selectedItems.length > 0 ? selectedItems.length : ''
          }`}</div>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
      {isOpen && renderDropdown()}
    </div>
  );
};

export default AnnotatorMultiSelectDropdown;
