import { fetchEnvelopeAttachments } from 'actions';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SectionType } from 'reducers/envelopeReview';
import {
  getEnvelopeHasAttachmentHit,
  getSelectedEnvelopeAttachments,
  getSelectedSection,
} from 'selectors/envelopeReview';
import { useSelector } from 'store';
import AttachmentItem from './AttachmentItem';

const EnvelopeAttachments: React.FC = () => {
  const { envelopeId } = useParams<{ envelopeId: string }>();
  const dispatch = useDispatch();

  const attachments = useSelector(getSelectedEnvelopeAttachments);

  const section: SectionType = 'attachment';
  const selectedSection = useSelector(getSelectedSection);
  const hasAttachmentHit = useSelector(getEnvelopeHasAttachmentHit);

  const [onlyMatches, setOnlyMatches] = useState(false);

  useEffect(() => {
    dispatch(fetchEnvelopeAttachments({ envelopeUuid: envelopeId }));
  }, [dispatch, envelopeId]);

  if (attachments.length < 1) return null;

  return (
    <div className="w-full flex flex-col bg-white border border-litlingo-gray-0.5 overflow-hidden text-small">
      <div
        className={`h-8 w-full flex flex-row items-center justify-between px-3 ${
          selectedSection === section ? 'bg-litlingo-highlight' : 'bg-white'
        }`}
      >
        <span className="font-bold">Attachments</span>
        {hasAttachmentHit && (
          <label htmlFor="only-matches" className="flex flex-row gap-2">
            <span>Only Matches</span>
            <input
              id="only-matches"
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
              value={onlyMatches.toString()}
              onChange={(): void => setOnlyMatches(!onlyMatches)}
            />
          </label>
        )}
      </div>
      <div className="flex flex-col">
        {attachments.map((attachment) => (
          <AttachmentItem key={attachment.id} attachment={attachment} onlyMatches={onlyMatches} />
        ))}
      </div>
    </div>
  );
};

export default EnvelopeAttachments;
