const userNotificationMsgs = {
  unsavedChanges: 'This page has unsaved changes',
};

export const sectionNotficationMesgs = {
  unsavedChanges: 'This section has unsaved changes',
};

export const languageMatcherNotificationMsgs = {
  unsavedChanges: 'Logic has unsaved changes',
};

export default userNotificationMsgs;
