import {
  fetchCustomerRecipientsDomainRequest,
  fetchCustomerUsersDomainRequest,
  fetchUserRequest,
  fetchUsersCountRequest,
  fetchUsersRequest,
  saveUserRequest,
} from 'actions';
import { GlobalState } from 'reducers';
import type {
  Customer,
  EntityMetadata,
  NormalizedResource,
  Role,
  Selector,
  UUID,
  User,
} from 'types';

export const getUsersLoading: Selector<boolean> = (state) =>
  state.users.loading.includes(fetchUsersRequest.toString());

export const getSelectedUser: Selector<User | null> = (state) => state.users.selectedUser;

export const getSingleUser: Selector<User, [string]> = (state, userId) => state.users.items[userId];

export const getUser =
  (userId: UUID | undefined): Selector<User | null> =>
  ({ users }): User | null =>
    userId ? users.items[userId] : null;

export const getUserLoading: Selector<boolean> = (state) =>
  state.users.loading.includes(saveUserRequest.toString()) ||
  state.users.loading.includes(fetchUserRequest.toString());

export const getUsers: Selector<NormalizedResource<User>> = (state) => state.users.items;

export const getUsersForFilterPills: Selector<User[]> = (state) => {
  const { filterItems } = state.users;

  return Object.keys(filterItems).map((uuid) => filterItems[uuid]);
};

export const getUsersTotalCount: Selector<number> = (state) => state.users.count;

export const getUsersTotalCountLoading: Selector<boolean> = (state) =>
  state.users.loading.includes(fetchUsersCountRequest.toString());

export const getAvailablesRoles: Selector<string[]> = (state) => state.users.availableRoles;

export const getUsersList: Selector<User[]> = (state) => {
  const { items } = state.users;

  return Object.keys(items).map((uuid) => items[uuid]);
};

export const getUsersNoSuperAdminList: Selector<User[]> = (state) => {
  const { items } = state.users;

  return Object.keys(items)
    .map((uuid) => items[uuid])
    .filter((user) => !user.roles.includes('super-admin'));
};

export const getUsersFilteredByRoles = (state: GlobalState, roles: Role[]): User[] => {
  const users = getUsersList(state);
  if (roles) {
    return users.filter((user) => roles.some((r) => user.roles && user.roles.includes(r)));
  }

  return users;
};

export const getUserShowHighlights: Selector<EntityMetadata | null> = (state) => {
  const showHighlights = state.users.currentUserMetadata;
  if (!showHighlights) return null;
  if (typeof showHighlights === 'object' && !Object.keys(showHighlights).length) return null;
  return state.users.currentUserMetadata;
};

export const getCustomerSenderDomain: Selector<{ uuid: string; name: string }[]> = (state) => {
  const { senderDomains } = state.users;
  return senderDomains
    .filter((domain) => domain.length > 0)
    .map((domain) => ({
      uuid: domain,
      name: domain,
    }));
};
export const getCustomerSenderDomainLoading: Selector<boolean> = (state) =>
  state.users.loading.includes(fetchCustomerUsersDomainRequest.toString());
export const getCustomerSenderDomainCount: Selector<number> = (state) =>
  state.users.senderDomainsCount;

export const getCustomerRecipientsDomain: Selector<{ uuid: string; name: string }[]> = (state) => {
  const { recipientsDomains } = state.users;
  return recipientsDomains
    .filter((domain) => domain.length > 0)
    .map((domain) => ({
      uuid: domain,
      name: domain,
    }));
};
export const getCustomerRecipientsDomainLoading: Selector<boolean> = (state) =>
  state.users.loading.includes(fetchCustomerRecipientsDomainRequest.toString());
export const getCustomerRecipientsDomainCount: Selector<number> = (state) =>
  state.users.recipientsDomainsCount;

export const getUserRolesCount: Selector<{ [key: string]: number }> = (state) => {
  const { rolesCount } = state.users;
  return rolesCount;
};

export const getUserIntegrationsCount: Selector<{ [key: string]: number }> = (state) => {
  const { integrationsCount } = state.users;
  return integrationsCount;
};

export const getTeamUsersCount: Selector<{ [key: string]: number }> = (state) => {
  const { teamUsersCount } = state.users;
  return teamUsersCount;
};

export const getMetricCustomers: Selector<Customer[]> = (state) => state.users.metricCustomers;
