import { selectCustomerIdentifier, setShowUtilization } from 'actions/identifier';
import SelectRedesign from 'components/Select/SelectRedesign';
import { EYE_ICON, EYE_SLASH_ICON } from 'constants/commonIcons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getIdentifierCustomers,
  getIdentifierShowUtilization,
  getSelectedCustomerIdentifier,
} from 'selectors/identifier';
import { useSelector } from 'store';

const IdentifierManagerUtilizationSection: React.FC = () => {
  const dispatch = useDispatch();

  const selectedCustomer = useSelector(getSelectedCustomerIdentifier);
  const customers = useSelector(getIdentifierCustomers);
  const showUtilization = useSelector(getIdentifierShowUtilization);

  useEffect(() => {
    if (selectedCustomer) {
      dispatch(setShowUtilization(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  const handleSelectCustomer = (customer: string): void => {
    dispatch(selectCustomerIdentifier(customer));
  };

  const toggleUtilization = (): void => {
    dispatch(setShowUtilization(!showUtilization));
  };

  let options = customers.map((c) => ({ label: c.name || '', value: c.uuid }));

  options = [{ label: 'All Workspaces', value: '' }, ...options];

  return (
    <div>
      <div className="p-4 flex flex-row items-center gap-4">
        <button type="button" className="w-8 focus:outline-none" onClick={toggleUtilization}>
          {showUtilization ? EYE_ICON : EYE_SLASH_ICON}
        </button>
        <SelectRedesign
          onChange={handleSelectCustomer}
          options={options}
          value={selectedCustomer || ''}
        />
      </div>
    </div>
  );
};

export default IdentifierManagerUtilizationSection;
